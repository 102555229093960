// Make sure the charset is set appropriately
@charset "UTF-8";

// fonts
@import url(http://fonts.googleapis.com/css?family=Inconsolata|Roboto|Oswald);

// This includes all of the foundation global elements that are needed to work with any of the other files.
@import "settings";

// Our vars
$medium-alert-color: #C86721;

@import "../bower_components/foundation/scss/foundation/functions";
// Foundation Components
@import
  //"foundation/components/tables",
  //"foundation/components/switch",
  "../bower_components/foundation/scss/foundation/components/global",
  "../bower_components/foundation/scss/foundation/components/grid",
  "../bower_components/foundation/scss/foundation/components/visibility",
  "../bower_components/foundation/scss/foundation/components/block-grid",
  "../bower_components/foundation/scss/foundation/components/type",
  "../bower_components/foundation/scss/foundation/components/buttons",
  "../bower_components/foundation/scss/foundation/components/forms",
  "../bower_components/foundation/scss/foundation/components/alert-boxes",
  "../bower_components/foundation/scss/foundation/components/labels",
  "../bower_components/foundation/scss/foundation/components/inline-lists",
  "../bower_components/foundation/scss/foundation/components/panels",
  "../bower_components/foundation/scss/foundation/components/pagination",
  "../bower_components/foundation/scss/foundation/components/progress-bars",
  "../bower_components/foundation/scss/foundation/components/reveal",
  "../bower_components/foundation/scss/foundation/components/tabs",
  "../bower_components/foundation/scss/foundation/components/top-bar",
  "../bower_components/foundation/scss/foundation/components/side-nav",
  "../bower_components/foundation/scss/foundation/components/sub-nav",
  "../bower_components/foundation/scss/foundation/components/switches",
  "../bower_components/foundation/scss/foundation/components/breadcrumbs",
  "../bower_components/foundation/scss/foundation/components/dropdown",
  "../bower_components/foundation/scss/foundation/components/dropdown-buttons";

// Zurb pizza 
$pie-color: $primary-color;
@import "pizza";

body {
}

h1 {
  font-size: emCalc(28px);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 0.03em 0.03em 0.03em rgba(#000, 0.3);
  margin-bottom: 1em;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
}

h2 {
  font-size: emCalc(20px);
  //text-transform: uppercase;
  //letter-spacing: 0.1em;

}

h3 {
  font-size: emCalc(16px);
  //text-transform: uppercase;
  //letter-spacing: 0.1em;

}

h4 {
  font-size: emCalc(12px);
  //text-transform: uppercase;
  //letter-spacing: 0.1em;
}

body.paulus {
  background: #fff url(../img/doge.png) fixed bottom right no-repeat;
}

.columns {
  transition: all 500ms;
}

#content {
  padding-bottom: 2em;
}

.deadline-budget {
  padding: 0 !important;
  line-height: 0;
  position: relative;
  div.progress {
    opacity: 0.66;
    padding: 0;
    margin: 0;
    border-width: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: auto;
  }
  div.progress:hover {
    opacity: 1;
  }
  div.deadline {
    top: 0%;
    bottom: 50%;
    border-bottom-width: 1px;
  }
  div.budget {
    top: 50%;
    bottom: 0%;
  }
}

div.progress {
  line-height: 1.3;
  height: 2.5em;
  .meter {
    transition: width ease-in-out 500ms;
  }
  i {
    color: #fff;
    margin-left: 0.3em;
    font-size: 1.4em;
  }
}

.form {
  .errorMessage {
    font-size: 0.6em;
    background-color: #c60f13;
    color: #fff;
    margin-bottom: 1.5em;
    padding: 0.5em;
  }
  span.required {
    display: none;
  }
  label.required {
    font-weight: bold;
  }
  input.hrs {
    text-align: right;
  }
  input.fixed-width {
    font-family: 'Inconsolata', monospace;
  }
  textarea {
    height: 6em;
  }
  textarea.autoExpand {
    height: auto;
  }
  small.helper {
    display: block;
    margin-top: -1.5em;
    margin-bottom: 1.2em;
    color: #999;
    line-height: 1.2;
  }
  .buttons {
    input {
      @include button;
      float: right;
    }
  }
  legend input {
    margin-bottom: 0;
  }
  .columns.active fieldset {
    background-color: #FFFDD0;
  }
  .postfix.button {
    margin-bottom: 0;
  }
}

table {
  border-spacing: 0;
  border-left: 1px dotted #eee;
  td, th {
    background-color: #fff;
    font-size: 0.8em;
    padding: 0.6em 0.4em;
    line-height: 1.25;
  }
  thead {
    td, th {
      font-size: 0.7em;
      font-weight: normal;
      text-align: left;
      background-color: rgba(#BDD0C9, 0.2);
      text-transform: uppercase;
    }
    tr.filters {
      td {
        padding: 0;
      }
      input, select {
        margin-bottom: 0;
      }
    }
  }
  tbody {
    th, td {
      border-bottom: 1px solid #ddd;
      border-right: 1px dotted #eee;
    }
    th {
      width: 12em;
      text-align: left;
      font-weight: normal;
      border-right: 1px dotted #eee;
      color: #666;
    }
  }
  td.id, td.hrs, td.price, td.code {
    font-size: 0.9em;
    font-family: 'Inconsolata', monospace;
    text-align: right;
  }
  td.mono, tr.mono td {
    font-family: 'Inconsolata', monospace;
    font-size: 0.9em;
  }
  td.icon {
    width: 1em;
  }
  td.id {
    width: 3em;
  }
  td.code {
    text-align: left;
    width: 6em;
  }
  td.price {
    width: 7em;
  }
  td.hrs {
    width: 5em;
  }
  td.date {
    white-space: nowrap;
    text-align: right;
    width: 7em;
  }
  td.status {
    font-size: 0.7em;
    width: 10em;
  }
  td.billing {
    text-align: center;
  }
  td.phone {
    white-space: nowrap;
  }
  td.deadline-budget {
    div.progress {
      opacity: 0.33;
      i {
        display: none;
      }
    }
  }
  tr:hover {
    div.progress {
      opacity: 0.66;
    }
  }
  .assigned {
    text-align: center;
    em {
      color: #f00;
    }
  }
  i.fi-check {
    color: $success-color;
  }
  i.fi-minus {
    color: #ccc;
  }
}

.table-footer {
  margin-top: -2em;
  margin-bottom: 2em;
  margin-left: 1em;
  font-size: 0.7em;
}

.grid-view {
  margin-bottom: 2em;
  table {
    tbody {
      td {
        transition: background-color 100ms;
      }
      tr:hover td {
        cursor: pointer;
        background-color: #eee;
      }
    }
  }
}

table.detail-view {
  width: 100%;
  margin-bottom: 2em;
}

.panel {
  .operations {
    margin-left: 0;
  }
  table {
    thead {
      th,td {
        background-color: #ddd;
      }
    }
  }
}

.alert-box {
  line-height: 1 !important;
  .close {
    display: none;
    padding: 0 0.2em 0 0;
  }
}
.alert-box:hover {
  .close {
    display: block;
  }
}

.project-status {
  ul {
    list-style-type: none;
    li {
      padding: 0.5em 0.5em;
    }
    li.active {
      background-color: $primary-color;
      font-weight: bold;
      a {
        display: block;
        span {
          color: #fff !important;
        }
      }
    }
    li.active.pending {
      background-color: $body-font-color;
    }
    li.active.inprogress {
      background-color: $primary-color;
    }
    li.active.onhold {
      background-color: $alert-color;
    }
    li.active.approval {
      background-color: $medium-alert-color;
    }
    li.active.complete {
      background-color: $success-color;
    }
    li.active.invoiced {
      background-color: $body-font-color;
    }
    li.active.cancelled {
      background-color: $body-font-color;
    }
  }
}

span.status {
  white-space: nowrap;
}

span.status.pending {
  color: $body-font-color;
}

span.status.inprogress {
  color: $primary-color;
}

span.status.onhold {
  color: $alert-color;
}

span.status.approval {
  color: $medium-alert-color;
}

span.status.complete {
  color: $success-color;
}

span.status.invoiced {
  color: $body-font-color;
}

span.status.cancelled {
  color: $body-font-color;
}

.priority.low {
  color: $success-color;
}

.priority.med {
  color: $medium-alert-color;
}

.priority.high {
  color: $alert-color;
}

.project-priority {
  ul {
    list-style-type: none;
    li {
      padding: 0.5em 0.5em;
    }
    li.low a {
      color: $success-color;
    }
    li.med a {
      color: $medium-alert-color;
    }
    li.high a {
      color: $alert-color;
    }
    li.active {
      font-weight: bold;
      a {
        display: block;
        color: #fff;
      }
    }
    li.active.low {
      background-color: $success-color;
    }
    li.active.med {
      background-color: $medium-alert-color;
    }
    li.active.high {
      background-color: $alert-color;
    }
  }
}

.project-users {
  ul {
    list-style-type: none;
  }
}

.activity-log {
  line-height: 1.4;
  font-size: 0.7em;
  div {
    padding: 0 0 1em;
  }
  time {
    color: #ccc;
    font-size: 0.85em;
    display: block;
    text-align: right;
  }
  div:hover {
    time {
      color: $small-font-color;
    }
  }
  .field {
    font-style: italic; 
  }
  .null {
    font-style: italic; 
  }
  .value {
    font-weight: bold;
  }
}

.assign-users {
  ul {
    list-style-type: none;
    a {
      width: 100%;
    }
  }
}

.panel.callout {
  a {
    color: #fff !important;
  }
}

ul.ui-autocomplete {
  z-index: 9 !important;
  li {
    font-size: 0.8em;
  }
}

.pager {
  @include pagination;
  font-size: 0.8em;
  padding-top: 1em;
  .selected {
    @include pagination-current-item;
  }
}

.portlet {
  @include panel;
  font-size: 0.9em;
  .portlet-title {
    font-weight: bold;
  }
  .operations {
    list-style-type: none;
    margin-bottom: 0;
    margin-left: 0;
    a {
      @include button($button-sml, $primary-color, false, true);
    }
    a.alert {
      @include button($button-sml, $alert-color, false, true);
    }
  }
}

.summary, .note {
  font-size: $small-font-size;
  padding: 0 0 1em;
  opacity: 0.7;
}

.top-bar {
  .title-area {
    h1 {
      width: 12em;
    }
  }
}

.pager li {
  font-size: 0.7em;
  margin-left: 0;
  a {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.row.dashboard {
  width: 100% !important;
  max-width: none;
  table {
    width: 100%;
    .users, .client {
      font-size: 0.7em;
    }
    .client {
      white-space: nowrap;
    }
  }
}

#dash-toinvoice-grid {
}

.deadline-list {
  h3 {
    color: #fff;
  }
  td.title {
    font-size: 0.6em;
  }
  td.date, td.code {
    font-size: 0.7em;
  }
}

.deadline-list.overdue {
  background-color: $alert-color;
  animation: overdue 1s ease-in 0 2;
}

@keyframes overdue
{
  0% { background-color: $alert-color; }
  50% { background-color: $panel-bg; }
  100% { background-color: $alert-color; }
}

.deadline-list.thisweek {
  background-color: $medium-alert-color;
}

.deadline-list.nextweek {
  background-color: $success-color;
}

.job-list {
  padding: 0.5em 0;
  table {
    width: 100%;
    td.deadline-budget {
      width: 8em;
    }
  }
}

.task-list {
  .task {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding: 0.5em 0;
    background-color: #fff;
    i.sort, .check, .uncheck, a.remove {
      position: absolute;
      top: 0.45em;
      font-size: 1.2em;
    }
    i.sort {
      left: 0;
      display: none;
    }
    i.sort:hover {
      color: $primary-color;
    }
    a.remove {
      right: 0;
      color: #222;
      display: none;
    }
    a.remove:hover {
      color: $primary-color;
    }
    .check, .uncheck {
      left: 1.2em;
    }
    a.check, a.uncheck {
      color: #222;
    }
    a.check:hover {
      color: $primary-color;
    }
    .textwrap {
      margin-left: 3em;
      margin-right: 2em;
    }
    textarea {
      box-shadow: none;
      padding: 0;
      overflow: hidden;
      min-height: 0;
      height: 1.2em;
      border-width: 0;
      resize: none;
      margin-bottom: 0;
      font-size: 0.8em;
    }
    textarea:focus {
      background-color: #fff;
    }
  }
  .task:hover {
    i.sort, a.remove {
      display: block;
    }
  }
  .done {
    text-decoration: line-through;
    color: #aaa;
    textarea, a.check, a.uncheck, a.remove {
      color: #aaa;
    }
  }
  .more {
    padding-top: 0.5em;
    font-size: 0.8em;
    font-weight: bold;
  }
}
.overlay {
  display: none;
  height: 100%;
  min-height: 100%;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: white;
  opacity: 0.5;
}
#facebookG{
  width:70px;
  position:absolute;
  left:50%;
  margin-left:-35px;
  top:50%;
  margin-top:-25px;
  .facebook_blockG {
    background-color:#B8B8B8;
    border:2px solid #757575;
    float:left;
    height:49px;
    margin-left:4px;
    width:13px;
    opacity:0.1;
    -moz-animation-name:bounceG;
    -moz-animation-duration:1.4s;
    -moz-animation-iteration-count:infinite;
    -moz-animation-direction:linear;
    -moz-transform:scale(0.7);
    -webkit-animation-name:bounceG;
    -webkit-animation-duration:1.4s;
    -webkit-animation-iteration-count:infinite;
    -webkit-animation-direction:linear;
    -webkit-transform:scale(0.7);
    -ms-animation-name:bounceG;
    -ms-animation-duration:1.4s;
    -ms-animation-iteration-count:infinite;
    -ms-animation-direction:linear;
    -ms-transform:scale(0.7);
    -o-animation-name:bounceG;
    -o-animation-duration:1.4s;
    -o-animation-iteration-count:infinite;
    -o-animation-direction:linear;
    -o-transform:scale(0.7);
    animation-name:bounceG;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-direction:linear;
    transform:scale(0.7);
  }
  #blockG_1{
    -moz-animation-delay:0.41999999999999993s;
    -webkit-animation-delay:0.41999999999999993s;
    -ms-animation-delay:0.41999999999999993s;
    -o-animation-delay:0.41999999999999993s;
    animation-delay:0.41999999999999993s;
  }

  #blockG_2{
    -moz-animation-delay:0.5599999999999999s;
    -webkit-animation-delay:0.5599999999999999s;
    -ms-animation-delay:0.5599999999999999s;
    -o-animation-delay:0.5599999999999999s;
    animation-delay:0.5599999999999999s;
  }

  #blockG_3{
    -moz-animation-delay:0.7s;
    -webkit-animation-delay:0.7s;
    -ms-animation-delay:0.7s;
    -o-animation-delay:0.7s;
    animation-delay:0.7s;
  }
}

@-moz-keyframes bounceG {
  0% {
    -moz-transform:scale(1.2);
    opacity:1
  }

  100% {
    -moz-transform:scale(0.7);
    opacity:0.1
  }
}

@-webkit-keyframes bounceG {
  0% {
    -webkit-transform:scale(1.2);
    opacity:1
  }

  100% {
    -webkit-transform:scale(0.7);
    opacity:0.1
  }
}

@-ms-keyframes bounceG {
  0% {
    -ms-transform:scale(1.2);
    opacity:1
  }

  100% {
    -ms-transform:scale(0.7);
    opacity:0.1
  }
}

@-o-keyframes bounceG {
  0% {
    -o-transform:scale(1.2);
    opacity:1
  }

  100% {
    -o-transform:scale(0.7);
    opacity:0.1
  }
}

@keyframes bounceG {
  0% {
    transform:scale(1.2);
    opacity:1
  }

  100% {
    transform:scale(0.7);
    opacity:0.1
  }
}


#fullscreen {
  padding: 0 2em;
}

.row.fullscreen {
  padding: 0 2em;
  width: 100% !important;
  max-width: none;
}

i.fi-loop {
  display: block;
  animation: spin 1s linear 0 infinite;
}

@keyframes spin
{
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#notify-nav.unread {
  a {
    animation: notify 1s ease-in-out 0 infinite;
  }
  a:hover {
    animation: none;
    background-color: $topbar-link-bg-hover;
  }
}

@keyframes notify
{
  0% { background-color: $topbar-bg-color; color: #fff; }
  50% { background-color: $success-color; color: $topbar-bg-color; }
  100% { background-color: $topbar-bg-color; color: #fff; }
}

#notifications {
  position: absolute;
  top: 4em;
  left: 16.7em;
  width: 32em;
  background-color: $topbar-bg-color;
  font-size: 0.7em;
  list-style-type: none;
  z-index: 99;
  a {
    border-top: 1px solid scale-color($topbar-bg-color, $lightness: 13%);
    color: #fff;
    display: block;
    padding: 0.75em;
  }
  a:hover {
    background-color: $topbar-link-bg-hover !important;
  }
  li.unread a {
    background-color: scale-color($topbar-bg-color, $lightness: 10%);
  }
  time {
    font-size: 0.8em;
    display: block;
    color: scale-color($topbar-bg-color, $lightness: 50%);
  }
  span.status.invoiced {
    color: #ccc;
  }
}

#timesheetPanel {
  position: fixed;
  bottom: 0;
  right: 0;
  transition: height 250ms;
  opacity: 0.9;
  background-color: $topbar-bg-color;
  font-size: 0.8em;
  width: 30em;
  z-index: 99;
  ul {
    font-size: 1em;
    list-style-type: none;
    margin: 0;
  }
  li {
    position: relative;
    height: 2em;
    line-height: 2em;
    transition: all 250ms;
    border-bottom: 1px solid scale-color($topbar-bg-color, $lightness: 13%);
  }
  li:hover {
    background-color: $topbar-link-bg-hover;
  }
  li.active {
    background-color: darken($success-color, 10%);
    border-bottom: 1px solid scale-color($success-color, $lightness: 13%);
  }
  li.active:hover {
      background-color: $success-color;
  }
  li.close {
    color: #999;
    text-align: center;
    font-size: 1.5em;
    border-bottom: none;
  }
  li.close:hover {
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }
  a {
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  a.punch {
    width: 2em;
    left: 0;
    text-align: center;
  }
  a.punch:hover {
    background-color: $success-color;
  }
  a.title {
    left: 2em;
    right: 5em;
    padding-left: 0.5em;
    overflow: hidden;
    span {
      display: block;
    }
  }
  a.time {
    width: 5em;
    right: 0;
    padding-right: 1em;
    text-align: right;
    font-family: 'Inconsolata', monospace;
  }
}
#timesheetPanel.collapsed {
  height: 2em;
}
#timesheetPanel:hover {
  opacity: .95;
}
#timesheetPanel.expanded {
  height: 18em;
  overflow: auto;
}

div.progress.medium-alert {
  .meter {
    background-color: $medium-alert-color;
  }
}

#user-dash {
  max-width: 80em;
  .noteListForm {
    width: 100%;
    .row {
      background-color: #f6f6f6;
    }
    .form-row {
      display: none;
    }
  }
  .summary {
    display: none;
  }
  .active-projects {
    .title {
    }
    a.client {
      color: $body-font-color;
    }
    a.client:hover {
      color: #fff;
      background-color: $body-font-color;
    }
  }
  .project-list {
    .title {
      line-height: 1.5;
    }
    .status, .priority {
      text-align: right;
      font-size: 0.9em;
      margin-bottom: 1em;
    }
    .task-list {
      font-size: 0.85em;
      .task {
        background-color: transparent;
        textarea {
          background-color: transparent;
        }
      }
    }
    .deadline-budget {
      height: 3.5em;
      margin-bottom: 1em;
      .progress
      {
        background-color: darken($panel-bg, 15%);
      }
      i {
        font-size: 1.3em;
      }
    }
  }
  .chart.timesheet {
    ul.data {
      display: none;
    }
    margin-bottom: 2em;
  }
  .chart.tasks {
    position: relative;
    ul.data {
      position: absolute;
      top: 0;
      z-index: 2;
      li {
        font-size: 0.85em;
      }
    }
    .todo {
      color: darken($panel-bg, 15%);
    }
    .completed {
      color: $success-color;
    }
    .diagram {
      height: 150px;
      font-size: 1.2em;
    }
  }
  .chart.timesheet {
    ul {
      li {
        color: $primary-color;
      } 
    }
  }
  .client {
    .phone, .address, .code {
      font-size: 0.8em;
      margin-top: 1em;
      line-height: 1.2;
    }
  }
}

.reminderList {
  .reminder {
    margin-bottom: 1em;
    background-color: #f3f3f3;
  }
  .remind.me {
    background-color: $alert-color;
  }
  .remind {
    background-color: $body-font-color;
    color: #fff;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    text-shadow: 1px 1px 1px #000;
  }
  .entity {
    background-color: $crumb-bg;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    a {
      color: $body-font-color;
    }
  }
  .text {
    font-size: 0.7em;
    padding: 1em;
  }
  .author {
    font-size: 0.6em;
    opacity: 0.7;
    padding: 0.5em;
  }
  .done {
    .button {
      opacity: 0.5;
      padding: 0.5em 0.75em;
      margin-bottom: 0.5em;
    }
    .button:hover {
      opacity: 1;
    }
  }
}

.reconcile-virtualmin {
  table.totals {
    margin-bottom: 2em;
    .total {
      font-weight: bold;
    }
    tbody tr.total td {
      border-top-style: double;
    }
    tbody td.total {
      border-left-style: double;
    }
    tr.disabled td, tr.disabled th {
      color: $alert-color;
    }
    tr.ip-mismatch td, tr.ip-mismatch th {
      color: $warning-color;
    }
  }
  #reconcile-grid {
    font-size: 0.8em;
    tr.disabled td, tr.disabled th {
      background-color: lighten($alert-color, 40%);
    }
    tr.ip-mismatch td, tr.ip-mismatch th {
      background-color: lighten($warning-color, 35%);
    }
    .select {
      padding: 0;
      a {
        padding: 0.2em 0.5em;
      }
    }
    .checkbox {
      padding: 0;
      input {
        margin-top: 0.5em;
      }
    }
    input, select {
      margin-bottom: 0;
      font-size: 1em;
      height: auto;
      padding: 0.1em 0.3em;
    }
    .date input {
    }
    .billingPriceCustom input {
      width: 8em;
    }
    select {
      height: 2em;
    }
  }
  .hasNotes {
    color: $alert-color;
    font-weight: bold;
  }
}

$notePadding: 4em;
.noteListForm {
  margin-bottom: 2em;
  form {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
  }
  textarea {
    line-height: 1.5;
    margin-bottom: 0.5em;
  }
  .name {
    //font-weight: bold;
  }
  .note-list {
    font-size: 0.8em;
    clear: both;
    .row {
      border-bottom: 1px solid darken($tabs-navigation-active-bg-color, 5);
    }
    .columns {
      padding-bottom: $notePadding;
      padding-top: $notePadding;
    }
    .date {
      color: #999;
      font-size: 0.8em;
    }
    .lead {
      font-size: 0.8em;
      position: absolute;
      left: 1.5em;
      top: 2em;
      color: $success-color;
      opacity: 1;
    }
    .type {
      font-size: 0.8em;
      position: absolute;
      right: 2em;
      top: 2em;
      line-height: 1;
    }
    .reminder {
      position: absolute;
      left: 1.5em;
      bottom: 1.7em;
      font-size: 0.8em;
      opacity: 1;
      text-transform: uppercase;
      .button {
        margin-bottom: 0;
        padding: 0.4em 0.4em 0.4em 0.4em;
      }
      .reminderLabel {
        margin-right: 1em;
      }
    }
    .reminder.done {
      .reminderLabel {
        opacity: 0.5;
      }
      .fa-check {
        color: $success-color;
        font-size: 1.5em;
      }
    }
    .rating {
      position: absolute;
      right: 2em;
      bottom: 1.7em;
      font-size: 0.8em;
      i {
        opacity: 1;
      }
    }
    .rating.rate1,
    .rating.rate2 {
      color: $alert-color;
    }
    .rating.rate3 {
      color: $warning-color;
    }
    .rating.rate4,
    .rating.rate5 {
      color: $success-color;
    }
  }
  .form-row {
    font-size: 0.8em;
    padding-top: $notePadding;
  }
  .fa {
    font-size: 1.1em;
  }
  .reminder {
    position: relative;
    opacity: 0.5;
    .fa {
      cursor: pointer;
    }
    input {
      margin-left: 1em;
      background-color: transparent;
      border-width: 0;
      box-shadow: none;
      height: 1.5em;
      padding: 0;
      width: auto;
      display: inline;
    }
    select {
      display: none;
      width: auto;
      background-color: transparent;
      border: none;
      padding: 0 2em 0 0.5em;
      margin-bottom: 0;
      height: 1.5em;
    }
  }
  .reminder:hover, .reminder.active {
    opacity: 1;
  }
  .reminder.active {
    select {
      display: inline;
    }
  }
  .type {
    padding-bottom: 0em;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    a.dropdown {
      opacity: 0.5;
    }
    a.dropdown:hover {
      opacity: 1;
    }
    input {
      font-size: 1em;
      display: none;
      width: 10em;
      margin-bottom: 0;
      height: 1em;
      padding-left: 0;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }
  .type.active {
    a.dropdown {
      opacity: 1;
    }
    input {
      display: inline;
    }
  }
  .lead {
    opacity: 0.5;
    line-height: 1;
    label.text {
      display: inline !important;
      color: $primary-color;
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 0.1em;
    }
    input {
      margin-bottom: 0;
    }
    .switch {
      line-height: 1;
      margin-bottom: 0;
      display: inline-block;
      label {
        height: 0.8em;
        width: 2em;
      }
      label:after {
        height: 0.5em;
        width: 0.6em;
        top: 0.1em;
      }
      input:checked + label:after {
        left: 1.1em;
      }
      input:checked + label {
        background-color: $success-color;
      }
    }
  }
  .lead:hover, .lead.active {
    opacity: 1;
  }
}
.noteListForm.dir-down {
  .note-list {
    .row {
      border-top: 1px solid darken($tabs-navigation-active-bg-color, 5);
      border-bottom: none;
    }
  }
  .form-row {
    padding-top: $notePadding;
    padding-bottom: 0;
  }
}


.rating {
  list-style-type: none;
  text-align: right;
  i {
    opacity: 0.5;
    font-size: 1.3em;
    cursor: pointer;
  }
  .avg {
    margin-left: 0.5em;
  }
  span:hover > i { opacity: 1; }
  span:hover > i.rate1 { color: $alert-color; }
  span:hover > i.rate2 { color: $warning-color; }
  span:hover > i.rate3 { color: $warning-color; }
  span:hover > i.rate4 { color: $success-color; }
  span:hover > i.rate5 { color: $success-color; }
}
.rating.active1 {
  i.rate1 { color: $alert-color; opacity: 1; font-weight: bold; }
}
.rating.active2 {
  i.rate1 { color: $alert-color; opacity: 1; }
  i.rate2 { color: $warning-color; opacity: 1; font-weight: bold; }
}
.rating.active3 {
  i.rate1 { color: $alert-color; opacity: 1; }
  i.rate2 { color: $warning-color; opacity: 1; }
  i.rate3 { color: $warning-color; opacity: 1; font-weight: bold; }
}
.rating.active4 {
  i.rate1 { color: $alert-color; opacity: 1; }
  i.rate2 { color: $warning-color; opacity: 1; }
  i.rate3 { color: $warning-color; opacity: 1; }
  i.rate4 { color: $success-color; opacity: 1; font-weight: bold; }
}
.rating.active5 {
  i.rate1 { color: $alert-color; opacity: 1; }
  i.rate2 { color: $warning-color; opacity: 1; }
  i.rate3 { color: $warning-color; opacity: 1; }
  i.rate4 { color: $success-color; opacity: 1; }
  i.rate5 { color: $success-color; opacity: 1; font-weight: bold; }
}

.tabs-content {
  background-color: $tabs-navigation-active-bg-color;
  padding: 1em;
  table tbody td {
    background-color: rgba(#fff, 0.7);
  }
  .noteListForm {
    background-color: rgba(#fff, 0.7);
  }
}

// remove active tab highlight
.tabs dd > a, .tabs .tab-title > a {
  padding: 0.5em 1.5em;
  outline: none;
  font-size: 0.9em;
}

dl.tabs {
  dd {
    a {
      border-right: 1px solid darken($tabs-navigation-bg-color, 10);
    }
    .count {
      position: absolute;
      bottom: 0.2em;
      right: 0.6em;
      font-size: 0.6em;
      opacity: 0.4;
    }
  }
  dd.active {
    a {
      border-right: none;
    }
  }
}

#project-grid td {
  font-size: 0.75em;
}

#client_notes {
  h2 {
    display: none;
  }
}

ul.sub-menu {
  @include sub-nav();
  list-style-type: none;
  float: right;
  overflow: visible;
  li {
    //text-transform: uppercase;
    font-size: 0.8em;
    margin: 0;
    a {
      padding: 0.5em 1em;
      color: $primary-color;
      background-color: rgba($primary-color, 0.1);
      border: 1px solid rgba($primary-color, 0.2);
      //border-radius: 0.2em;
    }
    a:hover {
      color: $primary-color;
      background-color: rgba($primary-color, 0.2);
      border: 1px solid rgba($primary-color, 0.3);
    }
  }
  a.alert {
    color: $alert-color;
  }
}

.client.view {
  .top-info {
    position: absolute;
    top: 2.5em;
    right: 1em;
  }
}

#client_dashboard {
  .summary {
    display: none;
  }
  table {
    width: 100%;
  }
  td {
    font-size: 0.8em !important;
  }
  #active-project-grid {
    thead {
      //display: none;
    }
  }
  .noteListForm {
    padding: 0 1em;
    h2,
    .form-row {
      display: none;
    }
  }
}
#client_notes {
  .noteListForm {
    padding: 0 2em;
  }
}
#client_contacts {
  table {
    clear: both;
  }
}

canvas.chart {
  margin-bottom: 2em;
}