// Pie Chart Variables

@mixin darkened-children($num-children, $color) {
  @for $i from 1 through $num-children {
    $color: scale-color($color, $lightness: -10%);
    & > *:nth-child(#{$num-children}n+#{$i}) { // This causes looping to occur after there are $num-children child elements.
      color: $color;
    }
  }
}

@mixin lightened-children($num-children, $color) {
  @for $i from 1 through $num-children {
    $color: scale-color($color, $lightness: 10%);
    & > *:nth-child(#{$num-children}n+#{$i}) { // This causes looping to occur after there are $num-children child elements.
      color: $color;
    }
  }
}

/*[data-pie-id], [data-bar-id], [data-line-id] {
  @include darkened-children(7, $pie-color);
}*/

.tooltip {
  margin-left: -5px;
  border-radius: 3px;
  padding: 5px;
}

/*svg text {
  fill: $label-color;
}*/

.chart {
  div.diagram {
    height: 200px;
  }
  svg {
    width: 100%;
    height: auto;
    transition: all 250ms;
  }
  ul.data {
    font-size: 0.8em;
  }
}