@import url(http://fonts.googleapis.com/css?family=Inconsolata|Roboto|Oswald);
meta.foundation-version {
  font-family: "/5.5.3/";
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0;
}

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.0625em)/";
  width: 40.0625em;
}

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/";
  width: 40.0625em;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.0625em)/";
  width: 64.0625em;
}

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
  width: 64.0625em;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.0625em)/";
  width: 90.0625em;
}

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width: 90.0625em;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.0625em)/";
  width: 120.0625em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

html, body {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

body {
  background: #fff;
  color: #1E2528;
  cursor: auto;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  margin: 0 auto;
  max-width: 62.5rem;
  width: 100%;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.row .row {
  margin: 0 -0.9375rem;
  max-width: none;
  width: auto;
}

.row .row:before, .row .row:after {
  content: " ";
  display: table;
}

.row .row:after {
  clear: both;
}

.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto;
}

.row .row.collapse:before, .row .row.collapse:after {
  content: " ";
  display: table;
}

.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.column + .column:last-child,
.columns + .column:last-child, .column +
.columns:last-child,
.columns +
.columns:last-child {
  float: right;
}

.column + .column.end,
.columns + .column.end, .column +
.columns.end,
.columns +
.columns.end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .small-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }
  .small-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }
  .small-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }
  .small-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }
  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .small-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }
  .small-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }
  .small-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }
  .small-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .small-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }
  .small-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }
  .small-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }
  .small-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }
  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .small-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }
  .small-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }
  .small-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }
  .small-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .small-1 {
    width: 8.3333333333%;
  }
  .small-2 {
    width: 16.6666666667%;
  }
  .small-3 {
    width: 25%;
  }
  .small-4 {
    width: 33.3333333333%;
  }
  .small-5 {
    width: 41.6666666667%;
  }
  .small-6 {
    width: 50%;
  }
  .small-7 {
    width: 58.3333333333%;
  }
  .small-8 {
    width: 66.6666666667%;
  }
  .small-9 {
    width: 75%;
  }
  .small-10 {
    width: 83.3333333333%;
  }
  .small-11 {
    width: 91.6666666667%;
  }
  .small-12 {
    width: 100%;
  }
  .small-offset-0 {
    margin-left: 0 !important;
  }
  .small-offset-1 {
    margin-left: 8.3333333333% !important;
  }
  .small-offset-2 {
    margin-left: 16.6666666667% !important;
  }
  .small-offset-3 {
    margin-left: 25% !important;
  }
  .small-offset-4 {
    margin-left: 33.3333333333% !important;
  }
  .small-offset-5 {
    margin-left: 41.6666666667% !important;
  }
  .small-offset-6 {
    margin-left: 50% !important;
  }
  .small-offset-7 {
    margin-left: 58.3333333333% !important;
  }
  .small-offset-8 {
    margin-left: 66.6666666667% !important;
  }
  .small-offset-9 {
    margin-left: 75% !important;
  }
  .small-offset-10 {
    margin-left: 83.3333333333% !important;
  }
  .small-offset-11 {
    margin-left: 91.6666666667% !important;
  }
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none;
  }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left;
  }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }
  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
}

@media only screen and (min-width: 40.0625em) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }
  .medium-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }
  .medium-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }
  .medium-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }
  .medium-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }
  .medium-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }
  .medium-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }
  .medium-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }
  .medium-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .medium-1 {
    width: 8.3333333333%;
  }
  .medium-2 {
    width: 16.6666666667%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-4 {
    width: 33.3333333333%;
  }
  .medium-5 {
    width: 41.6666666667%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-7 {
    width: 58.3333333333%;
  }
  .medium-8 {
    width: 66.6666666667%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-10 {
    width: 83.3333333333%;
  }
  .medium-11 {
    width: 91.6666666667%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-0 {
    margin-left: 0 !important;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333% !important;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667% !important;
  }
  .medium-offset-3 {
    margin-left: 25% !important;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333% !important;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667% !important;
  }
  .medium-offset-6 {
    margin-left: 50% !important;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333% !important;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667% !important;
  }
  .medium-offset-9 {
    margin-left: 75% !important;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333% !important;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667% !important;
  }
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none;
  }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left;
  }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }
  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }
  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }
  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }
  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }
  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }
  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }
  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }
  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }
  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }
  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
}

@media only screen and (min-width: 64.0625em) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }
  .large-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }
  .large-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }
  .large-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }
  .large-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }
  .large-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }
  .large-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }
  .large-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }
  .large-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .large-1 {
    width: 8.3333333333%;
  }
  .large-2 {
    width: 16.6666666667%;
  }
  .large-3 {
    width: 25%;
  }
  .large-4 {
    width: 33.3333333333%;
  }
  .large-5 {
    width: 41.6666666667%;
  }
  .large-6 {
    width: 50%;
  }
  .large-7 {
    width: 58.3333333333%;
  }
  .large-8 {
    width: 66.6666666667%;
  }
  .large-9 {
    width: 75%;
  }
  .large-10 {
    width: 83.3333333333%;
  }
  .large-11 {
    width: 91.6666666667%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-0 {
    margin-left: 0 !important;
  }
  .large-offset-1 {
    margin-left: 8.3333333333% !important;
  }
  .large-offset-2 {
    margin-left: 16.6666666667% !important;
  }
  .large-offset-3 {
    margin-left: 25% !important;
  }
  .large-offset-4 {
    margin-left: 33.3333333333% !important;
  }
  .large-offset-5 {
    margin-left: 41.6666666667% !important;
  }
  .large-offset-6 {
    margin-left: 50% !important;
  }
  .large-offset-7 {
    margin-left: 58.3333333333% !important;
  }
  .large-offset-8 {
    margin-left: 66.6666666667% !important;
  }
  .large-offset-9 {
    margin-left: 75% !important;
  }
  .large-offset-10 {
    margin-left: 83.3333333333% !important;
  }
  .large-offset-11 {
    margin-left: 91.6666666667% !important;
  }
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none;
  }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left;
  }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }
  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }
  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }
  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }
  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }
  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }
  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }
  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }
  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }
  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }
  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
}

/* small displays */
@media only screen {
  .show-for-small-only, .show-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-small-only, .hide-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .visible-for-small-only, .visible-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-small-only, .hidden-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.show-for-small-only, table.show-for-small-up, table.show-for-small, table.show-for-small-down, table.hide-for-medium-only, table.hide-for-medium-up, table.hide-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.show-for-small-only, thead.show-for-small-up, thead.show-for-small, thead.show-for-small-down, thead.hide-for-medium-only, thead.hide-for-medium-up, thead.hide-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.show-for-small-only, tbody.show-for-small-up, tbody.show-for-small, tbody.show-for-small-down, tbody.hide-for-medium-only, tbody.hide-for-medium-up, tbody.hide-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.show-for-small-only, tr.show-for-small-up, tr.show-for-small, tr.show-for-small-down, tr.hide-for-medium-only, tr.hide-for-medium-up, tr.hide-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.show-for-small-only, td.show-for-small-only, th.show-for-small-up, td.show-for-small-up, th.show-for-small, td.show-for-small, th.show-for-small-down, td.show-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.hide-for-medium-up, td.hide-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* medium displays */
@media only screen and (min-width: 40.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.show-for-medium-only, table.show-for-medium-up, table.show-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.show-for-medium-only, thead.show-for-medium-up, thead.show-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.show-for-medium-only, tbody.show-for-medium-up, tbody.show-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.show-for-medium-only, tr.show-for-medium-up, tr.show-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.show-for-medium-only, td.show-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.show-for-medium, td.show-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* large displays */
@media only screen and (min-width: 64.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.show-for-large-only, table.show-for-large-up, table.show-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.show-for-large-only, thead.show-for-large-up, thead.show-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.show-for-large-only, tbody.show-for-large-up, tbody.show-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.show-for-large-only, tr.show-for-large-up, tr.show-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.show-for-large-only, td.show-for-large-only, th.show-for-large-up, td.show-for-large-up, th.show-for-large, td.show-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* xlarge displays */
@media only screen and (min-width: 90.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.show-for-xlarge-only, table.show-for-xlarge-up, table.show-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.show-for-xlarge-only, thead.show-for-xlarge-up, thead.show-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.show-for-xlarge-only, tbody.show-for-xlarge-up, tbody.show-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.show-for-xlarge-only, tr.show-for-xlarge-up, tr.show-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.show-for-xlarge-only, td.show-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.show-for-xlarge, td.show-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* xxlarge displays */
@media only screen and (min-width: 120.0625em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .hide-for-xlarge-only, .show-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .show-for-xlarge-only, .hide-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .hidden-for-xlarge-only, .visible-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .visible-for-xlarge-only, .hidden-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .hidden-for-xxlarge-down {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.hide-for-xlarge-only, table.show-for-xlarge-up, table.hide-for-xlarge, table.hide-for-xlarge-down, table.show-for-xxlarge-only, table.show-for-xxlarge-up, table.show-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.hide-for-xlarge-only, thead.show-for-xlarge-up, thead.hide-for-xlarge, thead.hide-for-xlarge-down, thead.show-for-xxlarge-only, thead.show-for-xxlarge-up, thead.show-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.hide-for-xlarge-only, tbody.show-for-xlarge-up, tbody.hide-for-xlarge, tbody.hide-for-xlarge-down, tbody.show-for-xxlarge-only, tbody.show-for-xxlarge-up, tbody.show-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.hide-for-xlarge-only, tr.show-for-xlarge-up, tr.hide-for-xlarge, tr.hide-for-xlarge-down, tr.show-for-xxlarge-only, tr.show-for-xxlarge-up, tr.show-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.hide-for-xlarge-down, td.hide-for-xlarge-down, th.show-for-xxlarge-only, td.show-for-xxlarge-only, th.show-for-xxlarge-up, td.show-for-xxlarge-up, th.show-for-xxlarge, td.show-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
  display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-landscape, table.show-for-portrait {
  display: table !important;
}

thead.hide-for-landscape, thead.show-for-portrait {
  display: table-header-group !important;
}

tbody.hide-for-landscape, tbody.show-for-portrait {
  display: table-row-group !important;
}

tr.hide-for-landscape, tr.show-for-portrait {
  display: table-row !important;
}

td.hide-for-landscape, td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important;
}

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important;
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
  /* Specific visibility for tables */
  table.show-for-landscape, table.hide-for-portrait {
    display: table !important;
  }
  thead.show-for-landscape, thead.hide-for-portrait {
    display: table-header-group !important;
  }
  tbody.show-for-landscape, tbody.hide-for-portrait {
    display: table-row-group !important;
  }
  tr.show-for-landscape, tr.hide-for-portrait {
    display: table-row !important;
  }
  td.show-for-landscape, td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important;
  }
}

@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important;
  }
  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }
  /* Specific visibility for tables */
  table.show-for-portrait, table.hide-for-landscape {
    display: table !important;
  }
  thead.show-for-portrait, thead.hide-for-landscape {
    display: table-header-group !important;
  }
  tbody.show-for-portrait, tbody.hide-for-landscape {
    display: table-row-group !important;
  }
  tr.show-for-portrait, tr.hide-for-landscape {
    display: table-row !important;
  }
  td.show-for-portrait, td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important;
  }
}

/* Touch-enabled device targeting */
.show-for-touch {
  display: none !important;
}

.hide-for-touch {
  display: inherit !important;
}

.touch .show-for-touch {
  display: inherit !important;
}

.touch .hide-for-touch {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-touch {
  display: table !important;
}

.touch table.show-for-touch {
  display: table !important;
}

thead.hide-for-touch {
  display: table-header-group !important;
}

.touch thead.show-for-touch {
  display: table-header-group !important;
}

tbody.hide-for-touch {
  display: table-row-group !important;
}

.touch tbody.show-for-touch {
  display: table-row-group !important;
}

tr.hide-for-touch {
  display: table-row !important;
}

.touch tr.show-for-touch {
  display: table-row !important;
}

td.hide-for-touch {
  display: table-cell !important;
}

.touch td.show-for-touch {
  display: table-cell !important;
}

th.hide-for-touch {
  display: table-cell !important;
}

.touch th.show-for-touch {
  display: table-cell !important;
}

/* Screen reader-specific classes */
.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.show-on-focus {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.show-on-focus:focus, .show-on-focus:active {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
}

/* Print visibility */
.print-only,
.show-for-print {
  display: none !important;
}

@media print {
  .print-only,
  .show-for-print {
    display: block !important;
  }
  .hide-on-print,
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
}

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 -0.625rem;
}

[class*="block-grid-"]:before, [class*="block-grid-"]:after {
  content: " ";
  display: table;
}

[class*="block-grid-"]:after {
  clear: both;
}

[class*="block-grid-"] > li {
  display: block;
  float: left;
  height: auto;
  padding: 0 0.625rem 1.25rem;
}

@media only screen {
  .small-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }
  .small-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .small-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }
  .small-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .small-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%;
  }
  .small-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .small-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }
  .small-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .small-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }
  .small-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .small-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%;
  }
  .small-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .small-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%;
  }
  .small-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .small-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }
  .small-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .small-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%;
  }
  .small-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .small-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }
  .small-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .small-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%;
  }
  .small-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .small-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%;
  }
  .small-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 40.0625em) {
  .medium-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }
  .medium-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }
  .medium-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%;
  }
  .medium-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }
  .medium-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }
  .medium-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%;
  }
  .medium-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%;
  }
  .medium-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }
  .medium-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%;
  }
  .medium-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .medium-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }
  .medium-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .medium-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%;
  }
  .medium-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%;
  }
  .medium-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 64.0625em) {
  .large-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }
  .large-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .large-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }
  .large-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .large-block-grid-3 > li {
    list-style: none;
    width: 33.3333333333%;
  }
  .large-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .large-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }
  .large-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .large-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }
  .large-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .large-block-grid-6 > li {
    list-style: none;
    width: 16.6666666667%;
  }
  .large-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .large-block-grid-7 > li {
    list-style: none;
    width: 14.2857142857%;
  }
  .large-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .large-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }
  .large-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .large-block-grid-9 > li {
    list-style: none;
    width: 11.1111111111%;
  }
  .large-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .large-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }
  .large-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .large-block-grid-11 > li {
    list-style: none;
    width: 9.0909090909%;
  }
  .large-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .large-block-grid-12 > li {
    list-style: none;
    width: 8.3333333333%;
  }
  .large-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 40em) {
  .small-only-text-left {
    text-align: left !important;
  }
  .small-only-text-right {
    text-align: right !important;
  }
  .small-only-text-center {
    text-align: center !important;
  }
  .small-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen {
  .small-text-left {
    text-align: left !important;
  }
  .small-text-right {
    text-align: right !important;
  }
  .small-text-center {
    text-align: center !important;
  }
  .small-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .medium-only-text-left {
    text-align: left !important;
  }
  .medium-only-text-right {
    text-align: right !important;
  }
  .medium-only-text-center {
    text-align: center !important;
  }
  .medium-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.0625em) {
  .medium-text-left {
    text-align: left !important;
  }
  .medium-text-right {
    text-align: right !important;
  }
  .medium-text-center {
    text-align: center !important;
  }
  .medium-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .large-only-text-left {
    text-align: left !important;
  }
  .large-only-text-right {
    text-align: right !important;
  }
  .large-only-text-center {
    text-align: center !important;
  }
  .large-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.0625em) {
  .large-text-left {
    text-align: left !important;
  }
  .large-text-right {
    text-align: right !important;
  }
  .large-text-center {
    text-align: center !important;
  }
  .large-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.0625em) and (max-width: 120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }
  .xlarge-only-text-right {
    text-align: right !important;
  }
  .xlarge-only-text-center {
    text-align: center !important;
  }
  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.0625em) {
  .xlarge-text-left {
    text-align: left !important;
  }
  .xlarge-text-right {
    text-align: right !important;
  }
  .xlarge-text-center {
    text-align: center !important;
  }
  .xlarge-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.0625em) and (max-width: 6249999.9375em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }
  .xxlarge-only-text-right {
    text-align: right !important;
  }
  .xxlarge-only-text-center {
    text-align: center !important;
  }
  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.0625em) {
  .xxlarge-text-left {
    text-align: left !important;
  }
  .xxlarge-text-right {
    text-align: right !important;
  }
  .xxlarge-text-center {
    text-align: center !important;
  }
  .xxlarge-text-justify {
    text-align: justify !important;
  }
}

/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* Default Link Styles */
a {
  color: #00945D;
  line-height: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: #007f50;
}

a img {
  border: none;
}

/* Default paragraph styles */
p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}

p.lead {
  font-size: 1.21875rem;
  line-height: 1.6;
}

p aside {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.35;
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  color: #222222;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  text-rendering: optimizeLegibility;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #6f6f6f;
  font-size: 60%;
  line-height: 0;
}

h1 {
  font-size: 2.125rem;
}

h2 {
  font-size: 1.6875rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

hr {
  border: solid #DDDDDD;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 1.25rem 0 1.1875rem;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  background-color: #d3e0db;
  border-color: #bacec7;
  border-style: solid;
  border-width: 1px;
  color: #333333;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

/* Lists */
ul,
ol,
dl {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
  margin-bottom: 1.25rem;
}

ul {
  margin-left: 1.1rem;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit;
}

ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}

ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}

ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}

ol li ul,
ol li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

.no-bullet {
  list-style-type: none;
  margin-left: 0;
}

.no-bullet li ul,
.no-bullet li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none;
}

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

dl dd {
  margin-bottom: 0.75rem;
}

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #1E2528;
  cursor: help;
}

abbr {
  text-transform: none;
}

abbr[title] {
  border-bottom: 1px dotted #DDDDDD;
}

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #DDDDDD;
}

blockquote cite {
  display: block;
  font-size: 0.8125rem;
  color: #555555;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #555555;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f;
}

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #DDDDDD;
  padding: 0.625rem 0.75rem;
}

.vcard li {
  margin: 0;
  display: block;
}

.vcard .fn {
  font-weight: bold;
  font-size: 0.9375rem;
}

.vevent .summary {
  font-weight: bold;
}

.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: bold;
  border: none;
  padding: 0 0.0625rem;
}

@media only screen and (min-width: 40.0625em) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
  }
  h1 {
    font-size: 2.75rem;
  }
  h2 {
    font-size: 2.3125rem;
  }
  h3 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.4375rem;
  }
  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 1rem;
  }
}

/*
       * Print styles.
       *
       * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
       * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
      */
@media print {
  * {
    background: transparent !important;
    color: #000000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.34in;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

button, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem 2rem;
  font-size: 1rem;
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
  transition: background-color 300ms ease-out;
}

button:hover, button:focus, .button:hover, .button:focus {
  background-color: #00764a;
}

button:hover, button:focus, .button:hover, .button:focus {
  color: #FFFFFF;
}

button.secondary, .button.secondary {
  background-color: #6D9788;
  border-color: #57796d;
  color: #FFFFFF;
}

button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  background-color: #57796d;
}

button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  color: #FFFFFF;
}

button.success, .button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFFFFF;
}

button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  background-color: #368a55;
}

button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  color: #FFFFFF;
}

button.alert, .button.alert {
  background-color: #BF0A0D;
  border-color: #99080a;
  color: #FFFFFF;
}

button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  background-color: #99080a;
}

button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  color: #FFFFFF;
}

button.warning, .button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFFFFF;
}

button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  background-color: #cf6e0e;
}

button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  color: #FFFFFF;
}

button.info, .button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
}

button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  background-color: #61b6d9;
}

button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  color: #FFFFFF;
}

button.large, .button.large {
  padding: 1.125rem 2.25rem 1.1875rem 2.25rem;
  font-size: 1.25rem;
}

button.small, .button.small {
  padding: 0.875rem 1.75rem 0.9375rem 1.75rem;
  font-size: 0.8125rem;
}

button.tiny, .button.tiny {
  padding: 0.625rem 1.25rem 0.6875rem 1.25rem;
  font-size: 0.6875rem;
}

button.expand, .button.expand {
  padding: 1rem 2rem 1.0625rem 2rem;
  font-size: 1rem;
  padding-bottom: 1.0625rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

button.left-align, .button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}

button.right-align, .button.right-align {
  text-align: right;
  padding-right: 0.75rem;
}

button.radius, .button.radius {
  border-radius: 0px;
}

button.round, .button.round {
  border-radius: 1000px;
}

button.disabled, button[disabled], .button.disabled, .button[disabled] {
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #00764a;
}

button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  color: #FFFFFF;
}

button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #00945D;
}

button.disabled.secondary, button[disabled].secondary, .button.disabled.secondary, .button[disabled].secondary {
  background-color: #6D9788;
  border-color: #57796d;
  color: #FFFFFF;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #57796d;
}

button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  color: #FFFFFF;
}

button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #6D9788;
}

button.disabled.success, button[disabled].success, .button.disabled.success, .button[disabled].success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFFFFF;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #368a55;
}

button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  color: #FFFFFF;
}

button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #43AC6A;
}

button.disabled.alert, button[disabled].alert, .button.disabled.alert, .button[disabled].alert {
  background-color: #BF0A0D;
  border-color: #99080a;
  color: #FFFFFF;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #99080a;
}

button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  color: #FFFFFF;
}

button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #BF0A0D;
}

button.disabled.warning, button[disabled].warning, .button.disabled.warning, .button[disabled].warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFFFFF;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #cf6e0e;
}

button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  color: #FFFFFF;
}

button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #f08a24;
}

button.disabled.info, button[disabled].info, .button.disabled.info, .button[disabled].info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #61b6d9;
}

button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  color: #FFFFFF;
}

button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #a0d3e8;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media only screen and (min-width: 40.0625em) {
  button, .button {
    display: inline-block;
  }
}

/* Standard Forms */
form {
  margin: 0 0 1rem;
}

/* Using forms within rows, we need to set some defaults */
form .row .row {
  margin: 0 -0.5rem;
}

form .row .row .column,
form .row .row .columns {
  padding: 0 0.5rem;
}

form .row .row.collapse {
  margin: 0;
}

form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0;
}

form .row .row.collapse input {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: 0.5rem;
}

/* Label Styles */
label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
  /* Styles for required inputs */
}

label.right {
  float: none !important;
  text-align: right;
}

label.inline {
  margin: 0 0 1rem 0;
  padding: 0.5625rem 0;
}

label small {
  text-transform: capitalize;
  color: #676767;
}

/* Attach elements to the beginning or end of an input */
.prefix,
.postfix {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 0.875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

/* Adjust padding, alignment and radius if pre/post element is a button */
.postfix.button {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}

.prefix.button {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}

.prefix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.postfix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.prefix.button.round {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

.postfix.button.round {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

/* Separate prefix and postfix styles when on span or label so buttons keep their own */
span.prefix, label.prefix {
  background: #f2f2f2;
  border-right: none;
  color: #333333;
  border-color: #cccccc;
}

span.postfix, label.postfix {
  background: #f2f2f2;
  border-left: none;
  color: #333333;
  border-color: #cccccc;
}

/* We use this to get basic styling on all basic form elements */
input:not([type]), input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: inherit;
  font-size: 0.75rem;
  height: 2.1875rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.15s linear, background 0.15s linear;
  -moz-transition: border-color 0.15s linear, background 0.15s linear;
  -ms-transition: border-color 0.15s linear, background 0.15s linear;
  -o-transition: border-color 0.15s linear, background 0.15s linear;
  transition: border-color 0.15s linear, background 0.15s linear;
}

input:not([type]):focus, input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus {
  background: #fafafa;
  border-color: #999999;
  outline: none;
}

input:not([type]):disabled, input[type="text"]:disabled, input[type="password"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="month"]:disabled, input[type="week"]:disabled, input[type="email"]:disabled, input[type="number"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="color"]:disabled, textarea:disabled {
  background-color: #DDDDDD;
  cursor: default;
}

input:not([type])[disabled], input:not([type])[readonly],
fieldset[disabled] input:not([type]), input[type="text"][disabled], input[type="text"][readonly],
fieldset[disabled] input[type="text"], input[type="password"][disabled], input[type="password"][readonly],
fieldset[disabled] input[type="password"], input[type="date"][disabled], input[type="date"][readonly],
fieldset[disabled] input[type="date"], input[type="datetime"][disabled], input[type="datetime"][readonly],
fieldset[disabled] input[type="datetime"], input[type="datetime-local"][disabled], input[type="datetime-local"][readonly],
fieldset[disabled] input[type="datetime-local"], input[type="month"][disabled], input[type="month"][readonly],
fieldset[disabled] input[type="month"], input[type="week"][disabled], input[type="week"][readonly],
fieldset[disabled] input[type="week"], input[type="email"][disabled], input[type="email"][readonly],
fieldset[disabled] input[type="email"], input[type="number"][disabled], input[type="number"][readonly],
fieldset[disabled] input[type="number"], input[type="search"][disabled], input[type="search"][readonly],
fieldset[disabled] input[type="search"], input[type="tel"][disabled], input[type="tel"][readonly],
fieldset[disabled] input[type="tel"], input[type="time"][disabled], input[type="time"][readonly],
fieldset[disabled] input[type="time"], input[type="url"][disabled], input[type="url"][readonly],
fieldset[disabled] input[type="url"], input[type="color"][disabled], input[type="color"][readonly],
fieldset[disabled] input[type="color"], textarea[disabled], textarea[readonly],
fieldset[disabled] textarea {
  background-color: #DDDDDD;
  cursor: default;
}

input:not([type]).radius, input[type="text"].radius, input[type="password"].radius, input[type="date"].radius, input[type="datetime"].radius, input[type="datetime-local"].radius, input[type="month"].radius, input[type="week"].radius, input[type="email"].radius, input[type="number"].radius, input[type="search"].radius, input[type="tel"].radius, input[type="time"].radius, input[type="url"].radius, input[type="color"].radius, textarea.radius {
  border-radius: 0px;
}

form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse select,
form .row .prefix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

form .row .prefix-radius.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse textarea,
form .row .postfix-radius.row.collapse select,
form .row .postfix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

form .row .postfix-radius.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse textarea,
form .row .prefix-round.row.collapse select,
form .row .prefix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

form .row .prefix-round.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse textarea,
form .row .postfix-round.row.collapse select,
form .row .postfix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

form .row .postfix-round.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
}

/* Respect enforced amount of rows for textarea */
textarea[rows] {
  height: auto;
}

/* Not allow resize out of parent */
textarea {
  max-width: 100%;
}

::-webkit-input-placeholder {
  color: #666666;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
}

:-ms-input-placeholder {
  color: #666666;
}

/* Add height value for select elements to match text input height */
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #FAFAFA;
  border-radius: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: rgba(0, 0, 0, 0.75);
  font-family: inherit;
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.5rem;
  border-radius: 0;
  height: 2.1875rem;
}

select::-ms-expand {
  display: none;
}

select.radius {
  border-radius: 0px;
}

select:focus {
  background-color: #f3f3f3;
  border-color: #999999;
}

select:disabled {
  background-color: #DDDDDD;
  cursor: default;
}

select[multiple] {
  height: auto;
}

/* Adjust margin for form elements below */
input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
  margin: 0 0 1rem 0;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline;
}

/* Normalize file input width */
input[type="file"] {
  width: 100%;
}

/* HTML5 Number spinners settings */
/* We add basic fieldset styling */
fieldset {
  border: 1px solid #DDDDDD;
  margin: 1.125rem 0;
  padding: 1.25rem;
}

fieldset legend {
  font-weight: bold;
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

/* Error Handling */
[data-abide] .error small.error, [data-abide] .error span.error, [data-abide] span.error, [data-abide] small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #BF0A0D;
  color: #FFFFFF;
}

[data-abide] span.error, [data-abide] small.error {
  display: none;
}

span.error, small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #BF0A0D;
  color: #FFFFFF;
}

.error input,
.error textarea,
.error select {
  margin-bottom: 0;
}

.error input[type="checkbox"],
.error input[type="radio"] {
  margin-bottom: 1rem;
}

.error label,
.error label.error {
  color: #BF0A0D;
}

.error small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #BF0A0D;
  color: #FFFFFF;
}

.error > label > small {
  background: transparent;
  color: #676767;
  display: inline;
  font-size: 60%;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.error span.error-message {
  display: block;
}

input.error,
textarea.error,
select.error {
  margin-bottom: 0;
}

label.error {
  color: #BF0A0D;
}

.alert-box {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 0.8125rem;
  font-weight: normal;
  margin-bottom: 1.25rem;
  padding: 0.875rem 1.5rem 0.875rem 0.875rem;
  position: relative;
  transition: opacity 300ms ease-out;
  background-color: #00945D;
  border-color: #007f50;
  color: #FFFFFF;
}

.alert-box .close {
  right: 0.25rem;
  background: inherit;
  color: #333333;
  font-size: 1.375rem;
  line-height: .9;
  margin-top: -0.6875rem;
  opacity: 0.3;
  padding: 0 6px 4px;
  position: absolute;
  top: 50%;
}

.alert-box .close:hover, .alert-box .close:focus {
  opacity: 0.5;
}

.alert-box.radius {
  border-radius: 0px;
}

.alert-box.round {
  border-radius: 1000px;
}

.alert-box.success {
  background-color: #43AC6A;
  border-color: #3a945b;
  color: #FFFFFF;
}

.alert-box.alert {
  background-color: #BF0A0D;
  border-color: #a4090b;
  color: #FFFFFF;
}

.alert-box.secondary {
  background-color: #6D9788;
  border-color: #5d8375;
  color: #FFFFFF;
}

.alert-box.warning {
  background-color: #f08a24;
  border-color: #de770f;
  color: #FFFFFF;
}

.alert-box.info {
  background-color: #a0d3e8;
  border-color: #74bfdd;
  color: #25342e;
}

.alert-box.alert-close {
  opacity: 0;
}

.label {
  display: inline-block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
  margin-bottom: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  padding: 0.25rem 0.5rem 0.25rem;
  font-size: 0.6875rem;
  background-color: #00945D;
  color: #FFFFFF;
}

.label.radius {
  border-radius: 0px;
}

.label.round {
  border-radius: 1000px;
}

.label.alert {
  background-color: #BF0A0D;
  color: #FFFFFF;
}

.label.warning {
  background-color: #f08a24;
  color: #FFFFFF;
}

.label.success {
  background-color: #43AC6A;
  color: #FFFFFF;
}

.label.secondary {
  background-color: #6D9788;
  color: #FFFFFF;
}

.label.info {
  background-color: #a0d3e8;
  color: #333333;
}

.inline-list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 1.0625rem;
  margin-left: -1.375rem;
  margin-right: 0;
  overflow: hidden;
  padding: 0;
}

.inline-list > li {
  display: block;
  float: left;
  list-style: none;
  margin-left: 1.375rem;
}

.inline-list > li > * {
  display: block;
}

/* Panels */
.panel {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f2f2f2;
  color: #333333;
}

.panel > :first-child {
  margin-top: 0;
}

.panel > :last-child {
  margin-bottom: 0;
}

.panel h1, .panel h2, .panel h3, .panel h4, .panel h5, .panel h6, .panel p, .panel li, .panel dl {
  color: #333333;
}

.panel h1, .panel h2, .panel h3, .panel h4, .panel h5, .panel h6 {
  line-height: 1;
  margin-bottom: 0.625rem;
}

.panel h1.subheader, .panel h2.subheader, .panel h3.subheader, .panel h4.subheader, .panel h5.subheader, .panel h6.subheader {
  line-height: 1.4;
}

.panel.callout {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #e9fff7;
  color: #333333;
}

.panel.callout > :first-child {
  margin-top: 0;
}

.panel.callout > :last-child {
  margin-bottom: 0;
}

.panel.callout h1, .panel.callout h2, .panel.callout h3, .panel.callout h4, .panel.callout h5, .panel.callout h6, .panel.callout p, .panel.callout li, .panel.callout dl {
  color: #333333;
}

.panel.callout h1, .panel.callout h2, .panel.callout h3, .panel.callout h4, .panel.callout h5, .panel.callout h6 {
  line-height: 1;
  margin-bottom: 0.625rem;
}

.panel.callout h1.subheader, .panel.callout h2.subheader, .panel.callout h3.subheader, .panel.callout h4.subheader, .panel.callout h5.subheader, .panel.callout h6.subheader {
  line-height: 1.4;
}

.panel.callout a:not(.button) {
  color: #00945D;
}

.panel.callout a:not(.button):hover, .panel.callout a:not(.button):focus {
  color: #007f50;
}

.panel.radius {
  border-radius: 0px;
}

ul.pagination {
  display: block;
  margin-left: -0.3125rem;
  min-height: 1.5rem;
}

ul.pagination li {
  color: #222222;
  font-size: 0.875rem;
  height: 1.5rem;
  margin-left: 0.3125rem;
}

ul.pagination li a, ul.pagination li button {
  border-radius: 0px;
  transition: background-color 300ms ease-out;
  background: none;
  color: #999999;
  display: block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  padding: 0.0625rem 0.625rem 0.0625rem;
}

ul.pagination li:hover a,
ul.pagination li a:focus,
ul.pagination li:hover button,
ul.pagination li button:focus {
  background: #e6e6e6;
}

ul.pagination li.unavailable a, ul.pagination li.unavailable button {
  cursor: default;
  color: #999999;
  pointer-events: none;
}

ul.pagination li.unavailable:hover a,
ul.pagination li.unavailable a:focus,
ul.pagination li.unavailable:hover button,
ul.pagination li.unavailable button:focus {
  background: transparent;
}

ul.pagination li.current a, ul.pagination li.current button {
  background: #00945D;
  color: #FFFFFF;
  cursor: default;
  font-weight: bold;
}

ul.pagination li.current a:hover, ul.pagination li.current a:focus, ul.pagination li.current button:hover, ul.pagination li.current button:focus {
  background: #00945D;
}

ul.pagination li {
  display: block;
  float: left;
}

/* Pagination centred wrapper */
.pagination-centered {
  text-align: center;
}

.pagination-centered ul.pagination li {
  display: inline-block;
  float: none;
}

/* Progress Bar */
.progress {
  background-color: #F6F6F6;
  border: 1px solid white;
  height: 1.5625rem;
  margin-bottom: 0.625rem;
  padding: 0.125rem;
}

.progress .meter {
  background: #00945D;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress .meter.secondary {
  background: #6D9788;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress .meter.success {
  background: #43AC6A;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress .meter.alert {
  background: #BF0A0D;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress.secondary .meter {
  background: #6D9788;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress.success .meter {
  background: #43AC6A;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress.alert .meter {
  background: #BF0A0D;
  display: block;
  height: 100%;
  float: left;
  width: 0%;
}

.progress.radius {
  border-radius: 0px;
}

.progress.radius .meter {
  border-radius: -1px;
}

.progress.round {
  border-radius: 1000px;
}

.progress.round .meter {
  border-radius: 999px;
}

.reveal-modal-bg {
  background: #000000;
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1004;
  left: 0;
}

.reveal-modal {
  border-radius: 0px;
  display: none;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1005;
  left: 0;
  background-color: #FFFFFF;
  padding: 1.875rem;
  border: solid 1px #666666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 40em) {
  .reveal-modal {
    min-height: 100vh;
  }
}

.reveal-modal .column, .reveal-modal .columns {
  min-width: 0;
}

.reveal-modal > :first-child {
  margin-top: 0;
}

.reveal-modal > :last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 80%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal {
    top: 6.25rem;
  }
}

.reveal-modal.radius {
  box-shadow: none;
  border-radius: 0px;
}

.reveal-modal.round {
  box-shadow: none;
  border-radius: 1000px;
}

.reveal-modal.collapse {
  padding: 0;
  box-shadow: none;
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.tiny {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 30%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.small {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 40%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.medium {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 60%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.large {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 70%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.xlarge {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 95%;
  }
}

.reveal-modal.full {
  height: 100vh;
  height: 100%;
  left: 0;
  margin-left: 0 !important;
  max-width: none !important;
  min-height: 100vh;
  top: 0;
}

@media only screen and (min-width: 40.0625em) {
  .reveal-modal.full {
    left: 0;
    margin: 0 auto;
    max-width: 62.5rem;
    right: 0;
    width: 100%;
  }
}

.reveal-modal.toback {
  z-index: 1003;
}

.reveal-modal .close-reveal-modal {
  color: #AAAAAA;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 0.625rem;
  right: 1.375rem;
}

.tabs {
  margin-bottom: 0 !important;
  margin-left: 0;
}

.tabs:before, .tabs:after {
  content: " ";
  display: table;
}

.tabs:after {
  clear: both;
}

.tabs dd,
.tabs .tab-title {
  float: left;
  list-style: none;
  margin-bottom: 0 !important;
  position: relative;
}

.tabs dd > a,
.tabs .tab-title > a {
  display: block;
  background-color: #E6E1C2;
  color: #222222;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
}

.tabs dd > a:hover,
.tabs .tab-title > a:hover {
  background-color: #dfd8b0;
}

.tabs dd.active > a,
.tabs .tab-title.active > a {
  background-color: #f5f3e6;
  color: #222222;
}

.tabs.radius dd:first-child a,
.tabs.radius .tab:first-child a {
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.tabs.radius dd:last-child a,
.tabs.radius .tab:last-child a {
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.tabs.vertical dd,
.tabs.vertical .tab-title {
  position: inherit;
  float: none;
  display: block;
  top: auto;
}

.tabs-content {
  margin-bottom: 1.5rem;
  width: 100%;
}

.tabs-content:before, .tabs-content:after {
  content: " ";
  display: table;
}

.tabs-content:after {
  clear: both;
}

.tabs-content > .content {
  display: none;
  float: left;
  padding: 0.9375rem 0;
  width: 100%;
}

.tabs-content > .content.active {
  display: block;
  float: none;
}

.tabs-content > .content.contained {
  padding: 0.9375rem;
}

.tabs-content.vertical {
  display: block;
}

.tabs-content.vertical > .content {
  padding: 0 0.9375rem;
}

@media only screen and (min-width: 40.0625em) {
  .tabs.vertical {
    float: left;
    margin: 0;
    margin-bottom: 1.25rem !important;
    max-width: 20%;
    width: 20%;
  }
  .tabs-content.vertical {
    float: left;
    margin-left: -1px;
    max-width: 80%;
    padding-left: 1rem;
    width: 80%;
  }
}

.no-js .tabs-content > .content {
  display: block;
  float: none;
}

meta.foundation-mq-topbar {
  font-family: "/only screen and (min-width:46.5625em)/";
  width: 46.5625em;
}

/* Wrapped around .top-bar to contain to grid width */
.contain-to-grid {
  width: 100%;
  background: #1E2528;
}

.contain-to-grid .top-bar {
  margin-bottom: 0;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
}

.fixed.expanded:not(.top-bar) {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.fixed.expanded:not(.top-bar) .top-bar-section {
  margin-top: 2.8125rem;
  z-index: 98;
}

.top-bar {
  background: #1E2528;
  height: 2.8125rem;
  line-height: 2.8125rem;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}

.top-bar ul {
  list-style: none;
  margin-bottom: 0;
}

.top-bar .row {
  max-width: none;
}

.top-bar form,
.top-bar input,
.top-bar select {
  margin-bottom: 0;
}

.top-bar input,
.top-bar select {
  font-size: 0.75rem;
  height: 1.75rem;
  padding-bottom: .35rem;
  padding-top: .35rem;
}

.top-bar .button, .top-bar button {
  font-size: 0.75rem;
  margin-bottom: 0;
  padding-bottom: 0.4125rem;
  padding-top: 0.4125rem;
}

@media only screen and (max-width: 40em) {
  .top-bar .button, .top-bar button {
    position: relative;
    top: -1px;
  }
}

.top-bar .title-area {
  margin: 0;
  position: relative;
}

.top-bar .name {
  font-size: 16px;
  height: 2.8125rem;
  margin: 0;
}

.top-bar .name h1, .top-bar .name h2, .top-bar .name h3, .top-bar .name h4, .top-bar .name p, .top-bar .name span {
  font-size: 1.0625rem;
  line-height: 2.8125rem;
  margin: 0;
}

.top-bar .name h1 a, .top-bar .name h2 a, .top-bar .name h3 a, .top-bar .name h4 a, .top-bar .name p a, .top-bar .name span a {
  color: #FFFFFF;
  display: block;
  font-weight: normal;
  padding: 0 0.9375rem;
  width: 75%;
}

.top-bar .toggle-topbar {
  position: absolute;
  right: 0;
  top: 0;
}

.top-bar .toggle-topbar a {
  color: #FFFFFF;
  display: block;
  font-size: 0.8125rem;
  font-weight: bold;
  height: 2.8125rem;
  line-height: 2.8125rem;
  padding: 0 0.9375rem;
  position: relative;
  text-transform: uppercase;
}

.top-bar .toggle-topbar.menu-icon {
  margin-top: -16px;
  top: 50%;
}

.top-bar .toggle-topbar.menu-icon a {
  color: #FFFFFF;
  height: 34px;
  line-height: 33px;
  padding: 0 2.5rem 0 0.9375rem;
  position: relative;
}

.top-bar .toggle-topbar.menu-icon a span::after {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  margin-top: -8px;
  top: 50%;
  right: 0.9375rem;
  box-shadow: 0 0 0 1px #FFFFFF, 0 7px 0 1px #FFFFFF, 0 14px 0 1px #FFFFFF;
  width: 16px;
}

.top-bar .toggle-topbar.menu-icon a span:hover:after {
  box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px "";
}

.top-bar.expanded {
  background: transparent;
  height: auto;
}

.top-bar.expanded .title-area {
  background: #1E2528;
}

.top-bar.expanded .toggle-topbar a {
  color: #888888;
}

.top-bar.expanded .toggle-topbar a span::after {
  box-shadow: 0 0 0 1px #888888, 0 7px 0 1px #888888, 0 14px 0 1px #888888;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .top-bar.expanded .top-bar-section .has-dropdown.moved > .dropdown,
  .top-bar.expanded .top-bar-section .dropdown {
    clip: initial;
  }
  .top-bar.expanded .top-bar-section .has-dropdown:not(.moved) > ul {
    padding: 0;
  }
}

.top-bar-section {
  left: 0;
  position: relative;
  width: auto;
  transition: left 300ms ease-out;
}

.top-bar-section ul {
  display: block;
  font-size: 16px;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.top-bar-section .divider,
.top-bar-section [role="separator"] {
  border-top: solid 1px #0f1314;
  clear: both;
  height: 1px;
  width: 100%;
}

.top-bar-section ul li {
  background: #333333;
}

.top-bar-section ul li > a {
  color: #FFFFFF;
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: normal;
  padding-left: 0.9375rem;
  padding: 12px 0 12px 0.9375rem;
  text-transform: none;
  width: 100%;
}

.top-bar-section ul li > a.button {
  font-size: 0.8125rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
}

.top-bar-section ul li > a.button:hover, .top-bar-section ul li > a.button:focus {
  background-color: #00764a;
}

.top-bar-section ul li > a.button:hover, .top-bar-section ul li > a.button:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.secondary {
  background-color: #6D9788;
  border-color: #57796d;
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.secondary:hover, .top-bar-section ul li > a.button.secondary:focus {
  background-color: #57796d;
}

.top-bar-section ul li > a.button.secondary:hover, .top-bar-section ul li > a.button.secondary:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.success:hover, .top-bar-section ul li > a.button.success:focus {
  background-color: #368a55;
}

.top-bar-section ul li > a.button.success:hover, .top-bar-section ul li > a.button.success:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.alert {
  background-color: #BF0A0D;
  border-color: #99080a;
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.alert:hover, .top-bar-section ul li > a.button.alert:focus {
  background-color: #99080a;
}

.top-bar-section ul li > a.button.alert:hover, .top-bar-section ul li > a.button.alert:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.warning:hover, .top-bar-section ul li > a.button.warning:focus {
  background-color: #cf6e0e;
}

.top-bar-section ul li > a.button.warning:hover, .top-bar-section ul li > a.button.warning:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > a.button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
}

.top-bar-section ul li > a.button.info:hover, .top-bar-section ul li > a.button.info:focus {
  background-color: #61b6d9;
}

.top-bar-section ul li > a.button.info:hover, .top-bar-section ul li > a.button.info:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button {
  font-size: 0.8125rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
}

.top-bar-section ul li > button:hover, .top-bar-section ul li > button:focus {
  background-color: #00764a;
}

.top-bar-section ul li > button:hover, .top-bar-section ul li > button:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button.secondary {
  background-color: #6D9788;
  border-color: #57796d;
  color: #FFFFFF;
}

.top-bar-section ul li > button.secondary:hover, .top-bar-section ul li > button.secondary:focus {
  background-color: #57796d;
}

.top-bar-section ul li > button.secondary:hover, .top-bar-section ul li > button.secondary:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #FFFFFF;
}

.top-bar-section ul li > button.success:hover, .top-bar-section ul li > button.success:focus {
  background-color: #368a55;
}

.top-bar-section ul li > button.success:hover, .top-bar-section ul li > button.success:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button.alert {
  background-color: #BF0A0D;
  border-color: #99080a;
  color: #FFFFFF;
}

.top-bar-section ul li > button.alert:hover, .top-bar-section ul li > button.alert:focus {
  background-color: #99080a;
}

.top-bar-section ul li > button.alert:hover, .top-bar-section ul li > button.alert:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #FFFFFF;
}

.top-bar-section ul li > button.warning:hover, .top-bar-section ul li > button.warning:focus {
  background-color: #cf6e0e;
}

.top-bar-section ul li > button.warning:hover, .top-bar-section ul li > button.warning:focus {
  color: #FFFFFF;
}

.top-bar-section ul li > button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
}

.top-bar-section ul li > button.info:hover, .top-bar-section ul li > button.info:focus {
  background-color: #61b6d9;
}

.top-bar-section ul li > button.info:hover, .top-bar-section ul li > button.info:focus {
  color: #FFFFFF;
}

.top-bar-section ul li:hover:not(.has-form) > a {
  background-color: #555555;
  color: #FFFFFF;
  background: #222222;
}

.top-bar-section ul li.active > a {
  background: #00945D;
  color: #FFFFFF;
}

.top-bar-section ul li.active > a:hover {
  background: #007f50;
  color: #FFFFFF;
}

.top-bar-section .has-form {
  padding: 0.9375rem;
}

.top-bar-section .has-dropdown {
  position: relative;
}

.top-bar-section .has-dropdown > a:after {
  border: inset 5px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.4);
  border-left-style: solid;
  margin-right: 0.9375rem;
  margin-top: -4.5px;
  position: absolute;
  top: 50%;
  right: 0;
}

.top-bar-section .has-dropdown.moved {
  position: static;
}

.top-bar-section .has-dropdown.moved > .dropdown {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
  display: block;
  position: absolute !important;
  width: 100%;
}

.top-bar-section .has-dropdown.moved > a:after {
  display: none;
}

.top-bar-section .dropdown {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  display: block;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 99;
  left: 100%;
}

.top-bar-section .dropdown li {
  height: auto;
  width: 100%;
}

.top-bar-section .dropdown li a {
  font-weight: normal;
  padding: 8px 0.9375rem;
}

.top-bar-section .dropdown li a.parent-link {
  font-weight: normal;
}

.top-bar-section .dropdown li.title h5, .top-bar-section .dropdown li.parent-link {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.125rem;
}

.top-bar-section .dropdown li.title h5 a, .top-bar-section .dropdown li.parent-link a {
  color: #FFFFFF;
  display: block;
}

.top-bar-section .dropdown li.title h5 a:hover, .top-bar-section .dropdown li.parent-link a:hover {
  background: none;
}

.top-bar-section .dropdown li.has-form {
  padding: 8px 0.9375rem;
}

.top-bar-section .dropdown li .button,
.top-bar-section .dropdown li button {
  top: auto;
}

.top-bar-section .dropdown label {
  color: #777777;
  font-size: 0.625rem;
  font-weight: bold;
  margin-bottom: 0;
  padding: 8px 0.9375rem 2px;
  text-transform: uppercase;
}

.js-generated {
  display: block;
}

@media only screen and (min-width: 46.5625em) {
  .top-bar {
    background: #1E2528;
    overflow: visible;
  }
  .top-bar:before, .top-bar:after {
    content: " ";
    display: table;
  }
  .top-bar:after {
    clear: both;
  }
  .top-bar .toggle-topbar {
    display: none;
  }
  .top-bar .title-area {
    float: left;
  }
  .top-bar .name h1 a,
  .top-bar .name h2 a,
  .top-bar .name h3 a,
  .top-bar .name h4 a,
  .top-bar .name h5 a,
  .top-bar .name h6 a {
    width: auto;
  }
  .top-bar input,
  .top-bar select,
  .top-bar .button,
  .top-bar button {
    font-size: 0.875rem;
    height: 1.75rem;
    position: relative;
    top: 0.53125rem;
  }
  .top-bar .has-form > .button,
  .top-bar .has-form > button {
    font-size: 0.875rem;
    height: 1.75rem;
    position: relative;
    top: 0.53125rem;
  }
  .top-bar.expanded {
    background: #1E2528;
  }
  .contain-to-grid .top-bar {
    margin: 0 auto;
    margin-bottom: 0;
    max-width: 62.5rem;
  }
  .top-bar-section {
    transition: none 0 0;
    left: 0 !important;
  }
  .top-bar-section ul {
    display: inline;
    height: auto !important;
    width: auto;
  }
  .top-bar-section ul li {
    float: left;
  }
  .top-bar-section ul li .js-generated {
    display: none;
  }
  .top-bar-section li.hover > a:not(.button) {
    background-color: #555555;
    background: #222222;
    color: #FFFFFF;
  }
  .top-bar-section li:not(.has-form) a:not(.button) {
    background: #1E2528;
    line-height: 2.8125rem;
    padding: 0 0.9375rem;
  }
  .top-bar-section li:not(.has-form) a:not(.button):hover {
    background-color: #555555;
    background: #222222;
  }
  .top-bar-section li.active:not(.has-form) a:not(.button) {
    background: #00945D;
    color: #FFFFFF;
    line-height: 2.8125rem;
    padding: 0 0.9375rem;
  }
  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    background: #007f50;
    color: #FFFFFF;
  }
  .top-bar-section .has-dropdown > a {
    padding-right: 2.1875rem !important;
  }
  .top-bar-section .has-dropdown > a:after {
    border: inset 5px;
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-color: rgba(255, 255, 255, 0.4) transparent transparent transparent;
    border-top-style: solid;
    margin-top: -2.5px;
    top: 1.40625rem;
  }
  .top-bar-section .has-dropdown.moved {
    position: relative;
  }
  .top-bar-section .has-dropdown.moved > .dropdown {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    display: block;
  }
  .top-bar-section .has-dropdown.hover > .dropdown, .top-bar-section .has-dropdown.not-click:hover > .dropdown {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute !important;
  }
  .top-bar-section .has-dropdown > a:focus + .dropdown {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute !important;
  }
  .top-bar-section .has-dropdown .dropdown li.has-dropdown > a:after {
    border: none;
    content: "\00bb";
    top: 0.1875rem;
    right: 5px;
  }
  .top-bar-section .dropdown {
    left: 0;
    background: transparent;
    min-width: 100%;
    top: auto;
  }
  .top-bar-section .dropdown li a {
    background: #333333;
    color: #FFFFFF;
    line-height: 2.8125rem;
    padding: 12px 0.9375rem;
    white-space: nowrap;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
    background: #333333;
    color: #FFFFFF;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
    background-color: #555555;
    color: #FFFFFF;
    background: #222222;
  }
  .top-bar-section .dropdown li label {
    background: #333333;
    white-space: nowrap;
  }
  .top-bar-section .dropdown li .dropdown {
    left: 100%;
    top: 0;
  }
  .top-bar-section > ul > .divider,
  .top-bar-section > ul > [role="separator"] {
    border-right: solid 1px #374349;
    border-bottom: none;
    border-top: none;
    clear: none;
    height: 2.8125rem;
    width: 0;
  }
  .top-bar-section .has-form {
    background: #1E2528;
    height: 2.8125rem;
    padding: 0 0.9375rem;
  }
  .top-bar-section .right li .dropdown {
    left: auto;
    right: 0;
  }
  .top-bar-section .right li .dropdown li .dropdown {
    right: 100%;
  }
  .top-bar-section .left li .dropdown {
    right: auto;
    left: 0;
  }
  .top-bar-section .left li .dropdown li .dropdown {
    left: 100%;
  }
  .no-js .top-bar-section ul li:hover > a {
    background-color: #555555;
    background: #222222;
    color: #FFFFFF;
  }
  .no-js .top-bar-section ul li:active > a {
    background: #00945D;
    color: #FFFFFF;
  }
  .no-js .top-bar-section .has-dropdown:hover > .dropdown {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute !important;
  }
  .no-js .top-bar-section .has-dropdown > a:focus + .dropdown {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    display: block;
    position: absolute !important;
  }
}

.side-nav {
  display: block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  padding: 0.875rem 0;
}

.side-nav li {
  font-size: 0.875rem;
  font-weight: normal;
  margin: 0 0 0.4375rem 0;
}

.side-nav li a:not(.button) {
  color: #00945D;
  display: block;
  margin: 0;
  padding: 0.4375rem 0.875rem;
}

.side-nav li a:not(.button):hover, .side-nav li a:not(.button):focus {
  background: rgba(0, 0, 0, 0.025);
  color: #02ffa1;
}

.side-nav li a:not(.button):active {
  color: #02ffa1;
}

.side-nav li.active > a:first-child:not(.button) {
  color: #02ffa1;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.side-nav li.divider {
  border-top: 1px solid;
  height: 0;
  list-style: none;
  padding: 0;
  border-top-color: #e6e6e6;
}

.side-nav li.heading {
  color: #00945D;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sub-nav {
  display: block;
  margin: -0.25rem 0 1.125rem;
  overflow: hidden;
  padding-top: 0.25rem;
  width: auto;
}

.sub-nav dt {
  text-transform: uppercase;
}

.sub-nav dt,
.sub-nav dd,
.sub-nav li {
  color: #999999;
  float: left;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  margin-left: 1rem;
  margin-bottom: 0;
}

.sub-nav dt a,
.sub-nav dd a,
.sub-nav li a {
  color: #999999;
  padding: 0.1875rem 1rem;
  text-decoration: none;
}

.sub-nav dt a:hover,
.sub-nav dd a:hover,
.sub-nav li a:hover {
  color: #737373;
}

.sub-nav dt.active a,
.sub-nav dd.active a,
.sub-nav li.active a {
  border-radius: 3px;
  background: #00945D;
  color: #FFFFFF;
  cursor: default;
  font-weight: normal;
  padding: 0.1875rem 1rem;
}

.sub-nav dt.active a:hover,
.sub-nav dd.active a:hover,
.sub-nav li.active a:hover {
  background: #007f50;
}

.switch {
  border: none;
  margin-bottom: 1.5rem;
  outline: 0;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch label {
  background: #DDDDDD;
  color: transparent;
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
  position: relative;
  text-indent: 100%;
  width: 4rem;
  height: 2rem;
  transition: left 0.15s ease-out;
}

.switch input {
  left: 10px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 9px;
}

.switch input + label {
  margin-left: 0;
  margin-right: 0;
}

.switch label:after {
  background: #FFFFFF;
  content: "";
  display: block;
  height: 1.5rem;
  left: .25rem;
  position: absolute;
  top: .25rem;
  width: 1.5rem;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: translate3d(0, 0, 0);
  transition: left 0.15s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.switch input:checked + label {
  background: #00945D;
}

.switch input:checked + label:after {
  left: 2.25rem;
}

.switch label {
  height: 2rem;
  width: 4rem;
}

.switch label:after {
  height: 1.5rem;
  width: 1.5rem;
}

.switch input:checked + label:after {
  left: 2.25rem;
}

.switch label {
  color: transparent;
  background: #DDDDDD;
}

.switch label:after {
  background: #FFFFFF;
}

.switch input:checked + label {
  background: #00945D;
}

.switch.large label {
  height: 2.5rem;
  width: 5rem;
}

.switch.large label:after {
  height: 2rem;
  width: 2rem;
}

.switch.large input:checked + label:after {
  left: 2.75rem;
}

.switch.small label {
  height: 1.75rem;
  width: 3.5rem;
}

.switch.small label:after {
  height: 1.25rem;
  width: 1.25rem;
}

.switch.small input:checked + label:after {
  left: 2rem;
}

.switch.tiny label {
  height: 1.5rem;
  width: 3rem;
}

.switch.tiny label:after {
  height: 1rem;
  width: 1rem;
}

.switch.tiny input:checked + label:after {
  left: 1.75rem;
}

.switch.radius label {
  border-radius: 4px;
}

.switch.radius label:after {
  border-radius: 3px;
}

.switch.round {
  border-radius: 1000px;
}

.switch.round label {
  border-radius: 2rem;
}

.switch.round label:after {
  border-radius: 2rem;
}

.breadcrumbs {
  border-style: solid;
  border-width: 1px;
  display: block;
  list-style: none;
  margin-left: 0;
  overflow: hidden;
  padding: 0.5625rem 0.875rem 0.5625rem;
  background-color: #bdd0c9;
  border-color: #a6c0b7;
  border-radius: 0px;
}

.breadcrumbs > * {
  color: #00945D;
  float: left;
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  margin: 0;
  text-transform: uppercase;
}

.breadcrumbs > *:hover a, .breadcrumbs > *:focus a {
  text-decoration: underline;
}

.breadcrumbs > * a {
  color: #00945D;
}

.breadcrumbs > *.current {
  color: #333333;
  cursor: default;
}

.breadcrumbs > *.current a {
  color: #333333;
  cursor: default;
}

.breadcrumbs > *.current:hover, .breadcrumbs > *.current:hover a, .breadcrumbs > *.current:focus, .breadcrumbs > *.current:focus a {
  text-decoration: none;
}

.breadcrumbs > *.unavailable {
  color: #999999;
}

.breadcrumbs > *.unavailable a {
  color: #999999;
}

.breadcrumbs > *.unavailable:hover,
.breadcrumbs > *.unavailable:hover a, .breadcrumbs > *.unavailable:focus,
.breadcrumbs > *.unavailable a:focus {
  color: #999999;
  cursor: not-allowed;
  text-decoration: none;
}

.breadcrumbs > *:before {
  color: #AAAAAA;
  content: "/";
  margin: 0 0.75rem;
  position: relative;
  top: 1px;
}

.breadcrumbs > *:first-child:before {
  content: " ";
  margin: 0;
}

/* Accessibility - hides the forward slash */
[aria-label="breadcrumbs"] [aria-hidden="true"]:after {
  content: "/";
}

/* Foundation Dropdowns */
.f-dropdown {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 2px;
  max-width: 200px;
}

.f-dropdown.open {
  display: block;
}

.f-dropdown > *:first-child {
  margin-top: 0;
}

.f-dropdown > *:last-child {
  margin-bottom: 0;
}

.f-dropdown:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent #FFFFFF transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 89;
}

.f-dropdown:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent #cccccc transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -14px;
  left: 9px;
  z-index: 88;
}

.f-dropdown.right:before {
  left: auto;
  right: 10px;
}

.f-dropdown.right:after {
  left: auto;
  right: 9px;
}

.f-dropdown.drop-right {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 0;
  margin-left: 2px;
  max-width: 200px;
}

.f-dropdown.drop-right.open {
  display: block;
}

.f-dropdown.drop-right > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-right > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-right:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent #FFFFFF transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 10px;
  left: -12px;
  z-index: 89;
}

.f-dropdown.drop-right:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent #cccccc transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 9px;
  left: -14px;
  z-index: 88;
}

.f-dropdown.drop-left {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 0;
  margin-left: -2px;
  max-width: 200px;
}

.f-dropdown.drop-left.open {
  display: block;
}

.f-dropdown.drop-left > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-left > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-left:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #FFFFFF;
  border-left-style: solid;
  position: absolute;
  top: 10px;
  right: -12px;
  left: auto;
  z-index: 89;
}

.f-dropdown.drop-left:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #cccccc;
  border-left-style: solid;
  position: absolute;
  top: 9px;
  right: -14px;
  left: auto;
  z-index: 88;
}

.f-dropdown.drop-top {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-left: 0;
  margin-top: -2px;
  max-width: 200px;
}

.f-dropdown.drop-top.open {
  display: block;
}

.f-dropdown.drop-top > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-top > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-top:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: #FFFFFF transparent transparent transparent;
  border-top-style: solid;
  bottom: -12px;
  position: absolute;
  top: auto;
  left: 10px;
  right: auto;
  z-index: 89;
}

.f-dropdown.drop-top:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: #cccccc transparent transparent transparent;
  border-top-style: solid;
  bottom: -14px;
  position: absolute;
  top: auto;
  left: 9px;
  right: auto;
  z-index: 88;
}

.f-dropdown li {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
}

.f-dropdown li:hover, .f-dropdown li:focus {
  background: #EEEEEE;
}

.f-dropdown li a {
  display: block;
  padding: 0.5rem;
  color: #555555;
}

.f-dropdown.content {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  padding: 1.25rem;
  width: 100%;
  z-index: 89;
  max-width: 200px;
}

.f-dropdown.content.open {
  display: block;
}

.f-dropdown.content > *:first-child {
  margin-top: 0;
}

.f-dropdown.content > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.radius {
  border-radius: 0px;
}

.f-dropdown.tiny {
  max-width: 200px;
}

.f-dropdown.small {
  max-width: 300px;
}

.f-dropdown.medium {
  max-width: 500px;
}

.f-dropdown.large {
  max-width: 800px;
}

.f-dropdown.mega {
  width: 100% !important;
  max-width: 100% !important;
}

.f-dropdown.mega.open {
  left: 0 !important;
}

.dropdown.button, button.dropdown {
  position: relative;
  padding-right: 3.5625rem;
}

.dropdown.button::after, button.dropdown::after {
  border-color: #FFFFFF transparent transparent transparent;
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
}

.dropdown.button::after, button.dropdown::after {
  border-width: 0.375rem;
  right: 1.40625rem;
  margin-top: -0.15625rem;
}

.dropdown.button::after, button.dropdown::after {
  border-color: #FFFFFF transparent transparent transparent;
}

.dropdown.button.tiny, button.dropdown.tiny {
  padding-right: 2.625rem;
}

.dropdown.button.tiny:after, button.dropdown.tiny:after {
  border-width: 0.375rem;
  right: 1.125rem;
  margin-top: -0.125rem;
}

.dropdown.button.tiny::after, button.dropdown.tiny::after {
  border-color: #FFFFFF transparent transparent transparent;
}

.dropdown.button.small, button.dropdown.small {
  padding-right: 3.0625rem;
}

.dropdown.button.small::after, button.dropdown.small::after {
  border-width: 0.4375rem;
  right: 1.3125rem;
  margin-top: -0.15625rem;
}

.dropdown.button.small::after, button.dropdown.small::after {
  border-color: #FFFFFF transparent transparent transparent;
}

.dropdown.button.large, button.dropdown.large {
  padding-right: 3.625rem;
}

.dropdown.button.large::after, button.dropdown.large::after {
  border-width: 0.3125rem;
  right: 1.71875rem;
  margin-top: -0.15625rem;
}

.dropdown.button.large::after, button.dropdown.large::after {
  border-color: #FFFFFF transparent transparent transparent;
}

.dropdown.button.secondary:after, button.dropdown.secondary:after {
  border-color: #333333 transparent transparent transparent;
}

/*[data-pie-id], [data-bar-id], [data-line-id] {
  @include darkened-children(7, $pie-color);
}*/
.tooltip {
  margin-left: -5px;
  border-radius: 3px;
  padding: 5px;
}

/*svg text {
  fill: $label-color;
}*/
.chart div.diagram {
  height: 200px;
}

.chart svg {
  width: 100%;
  height: auto;
  transition: all 250ms;
}

.chart ul.data {
  font-size: 0.8em;
}

h1 {
  font-size: 1.75em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 0.03em 0.03em 0.03em rgba(0, 0, 0, 0.3);
  margin-bottom: 1em;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
}

h2 {
  font-size: 1.25em;
}

h3 {
  font-size: 1em;
}

h4 {
  font-size: 0.75em;
}

body.paulus {
  background: #fff url(../img/doge.png) fixed bottom right no-repeat;
}

.columns {
  transition: all 500ms;
}

#content {
  padding-bottom: 2em;
}

.deadline-budget {
  padding: 0 !important;
  line-height: 0;
  position: relative;
}

.deadline-budget div.progress {
  opacity: 0.66;
  padding: 0;
  margin: 0;
  border-width: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: auto;
}

.deadline-budget div.progress:hover {
  opacity: 1;
}

.deadline-budget div.deadline {
  top: 0%;
  bottom: 50%;
  border-bottom-width: 1px;
}

.deadline-budget div.budget {
  top: 50%;
  bottom: 0%;
}

div.progress {
  line-height: 1.3;
  height: 2.5em;
}

div.progress .meter {
  transition: width ease-in-out 500ms;
}

div.progress i {
  color: #fff;
  margin-left: 0.3em;
  font-size: 1.4em;
}

.form .errorMessage {
  font-size: 0.6em;
  background-color: #c60f13;
  color: #fff;
  margin-bottom: 1.5em;
  padding: 0.5em;
}

.form span.required {
  display: none;
}

.form label.required {
  font-weight: bold;
}

.form input.hrs {
  text-align: right;
}

.form input.fixed-width {
  font-family: 'Inconsolata', monospace;
}

.form textarea {
  height: 6em;
}

.form textarea.autoExpand {
  height: auto;
}

.form small.helper {
  display: block;
  margin-top: -1.5em;
  margin-bottom: 1.2em;
  color: #999;
  line-height: 1.2;
}

.form .buttons input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem 2rem;
  font-size: 1rem;
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
  transition: background-color 300ms ease-out;
  float: right;
}

.form .buttons input:hover, .form .buttons input:focus {
  background-color: #00764a;
}

.form .buttons input:hover, .form .buttons input:focus {
  color: #FFFFFF;
}

.form legend input {
  margin-bottom: 0;
}

.form .columns.active fieldset {
  background-color: #FFFDD0;
}

.form .postfix.button {
  margin-bottom: 0;
}

table {
  border-spacing: 0;
  border-left: 1px dotted #eee;
}

table td, table th {
  background-color: #fff;
  font-size: 0.8em;
  padding: 0.6em 0.4em;
  line-height: 1.25;
}

table thead td, table thead th {
  font-size: 0.7em;
  font-weight: normal;
  text-align: left;
  background-color: rgba(189, 208, 201, 0.2);
  text-transform: uppercase;
}

table thead tr.filters td {
  padding: 0;
}

table thead tr.filters input, table thead tr.filters select {
  margin-bottom: 0;
}

table tbody th, table tbody td {
  border-bottom: 1px solid #ddd;
  border-right: 1px dotted #eee;
}

table tbody th {
  width: 12em;
  text-align: left;
  font-weight: normal;
  border-right: 1px dotted #eee;
  color: #666;
}

table td.id, table td.hrs, table td.price, table td.code {
  font-size: 0.9em;
  font-family: 'Inconsolata', monospace;
  text-align: right;
}

table td.mono, table tr.mono td {
  font-family: 'Inconsolata', monospace;
  font-size: 0.9em;
}

table td.icon {
  width: 1em;
}

table td.id {
  width: 3em;
}

table td.code {
  text-align: left;
  width: 6em;
}

table td.price {
  width: 7em;
}

table td.hrs {
  width: 5em;
}

table td.date {
  white-space: nowrap;
  text-align: right;
  width: 7em;
}

table td.status {
  font-size: 0.7em;
  width: 10em;
}

table td.billing {
  text-align: center;
}

table td.phone {
  white-space: nowrap;
}

table td.deadline-budget div.progress {
  opacity: 0.33;
}

table td.deadline-budget div.progress i {
  display: none;
}

table tr:hover div.progress {
  opacity: 0.66;
}

table .assigned {
  text-align: center;
}

table .assigned em {
  color: #f00;
}

table i.fi-check {
  color: #43AC6A;
}

table i.fi-minus {
  color: #ccc;
}

.table-footer {
  margin-top: -2em;
  margin-bottom: 2em;
  margin-left: 1em;
  font-size: 0.7em;
}

.grid-view {
  margin-bottom: 2em;
}

.grid-view table tbody td {
  transition: background-color 100ms;
}

.grid-view table tbody tr:hover td {
  cursor: pointer;
  background-color: #eee;
}

table.detail-view {
  width: 100%;
  margin-bottom: 2em;
}

.panel .operations {
  margin-left: 0;
}

.panel table thead th, .panel table thead td {
  background-color: #ddd;
}

.alert-box {
  line-height: 1 !important;
}

.alert-box .close {
  display: none;
  padding: 0 0.2em 0 0;
}

.alert-box:hover .close {
  display: block;
}

.project-status ul {
  list-style-type: none;
}

.project-status ul li {
  padding: 0.5em 0.5em;
}

.project-status ul li.active {
  background-color: #00945D;
  font-weight: bold;
}

.project-status ul li.active a {
  display: block;
}

.project-status ul li.active a span {
  color: #fff !important;
}

.project-status ul li.active.pending {
  background-color: #1E2528;
}

.project-status ul li.active.inprogress {
  background-color: #00945D;
}

.project-status ul li.active.onhold {
  background-color: #BF0A0D;
}

.project-status ul li.active.approval {
  background-color: #C86721;
}

.project-status ul li.active.complete {
  background-color: #43AC6A;
}

.project-status ul li.active.invoiced {
  background-color: #1E2528;
}

.project-status ul li.active.cancelled {
  background-color: #1E2528;
}

span.status {
  white-space: nowrap;
}

span.status.pending {
  color: #1E2528;
}

span.status.inprogress {
  color: #00945D;
}

span.status.onhold {
  color: #BF0A0D;
}

span.status.approval {
  color: #C86721;
}

span.status.complete {
  color: #43AC6A;
}

span.status.invoiced {
  color: #1E2528;
}

span.status.cancelled {
  color: #1E2528;
}

.priority.low {
  color: #43AC6A;
}

.priority.med {
  color: #C86721;
}

.priority.high {
  color: #BF0A0D;
}

.project-priority ul {
  list-style-type: none;
}

.project-priority ul li {
  padding: 0.5em 0.5em;
}

.project-priority ul li.low a {
  color: #43AC6A;
}

.project-priority ul li.med a {
  color: #C86721;
}

.project-priority ul li.high a {
  color: #BF0A0D;
}

.project-priority ul li.active {
  font-weight: bold;
}

.project-priority ul li.active a {
  display: block;
  color: #fff;
}

.project-priority ul li.active.low {
  background-color: #43AC6A;
}

.project-priority ul li.active.med {
  background-color: #C86721;
}

.project-priority ul li.active.high {
  background-color: #BF0A0D;
}

.project-users ul {
  list-style-type: none;
}

.activity-log {
  line-height: 1.4;
  font-size: 0.7em;
}

.activity-log div {
  padding: 0 0 1em;
}

.activity-log time {
  color: #ccc;
  font-size: 0.85em;
  display: block;
  text-align: right;
}

.activity-log div:hover time {
  color: #6f6f6f;
}

.activity-log .field {
  font-style: italic;
}

.activity-log .null {
  font-style: italic;
}

.activity-log .value {
  font-weight: bold;
}

.assign-users ul {
  list-style-type: none;
}

.assign-users ul a {
  width: 100%;
}

.panel.callout a {
  color: #fff !important;
}

ul.ui-autocomplete {
  z-index: 9 !important;
}

ul.ui-autocomplete li {
  font-size: 0.8em;
}

.pager {
  display: block;
  margin-left: -0.3125rem;
  min-height: 1.5rem;
  font-size: 0.8em;
  padding-top: 1em;
}

.pager li {
  color: #222222;
  font-size: 0.875rem;
  height: 1.5rem;
  margin-left: 0.3125rem;
}

.pager li a, .pager li button {
  border-radius: 0px;
  transition: background-color 300ms ease-out;
  background: none;
  color: #999999;
  display: block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  padding: 0.0625rem 0.625rem 0.0625rem;
}

.pager li:hover a,
.pager li a:focus,
.pager li:hover button,
.pager li button:focus {
  background: #e6e6e6;
}

.pager li.unavailable a, .pager li.unavailable button {
  cursor: default;
  color: #999999;
  pointer-events: none;
}

.pager li.unavailable:hover a,
.pager li.unavailable a:focus,
.pager li.unavailable:hover button,
.pager li.unavailable button:focus {
  background: transparent;
}

.pager li.current a, .pager li.current button {
  background: #00945D;
  color: #FFFFFF;
  cursor: default;
  font-weight: bold;
}

.pager li.current a:hover, .pager li.current a:focus, .pager li.current button:hover, .pager li.current button:focus {
  background: #00945D;
}

.pager li {
  display: block;
  float: left;
}

.pager .selected a, .pager .selected button {
  background: #00945D;
  color: #FFFFFF;
  cursor: default;
  font-weight: bold;
}

.pager .selected a:hover, .pager .selected a:focus, .pager .selected button:hover, .pager .selected button:focus {
  background: #00945D;
}

.portlet {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f2f2f2;
  color: #333333;
  font-size: 0.9em;
}

.portlet > :first-child {
  margin-top: 0;
}

.portlet > :last-child {
  margin-bottom: 0;
}

.portlet h1, .portlet h2, .portlet h3, .portlet h4, .portlet h5, .portlet h6, .portlet p, .portlet li, .portlet dl {
  color: #333333;
}

.portlet h1, .portlet h2, .portlet h3, .portlet h4, .portlet h5, .portlet h6 {
  line-height: 1;
  margin-bottom: 0.625rem;
}

.portlet h1.subheader, .portlet h2.subheader, .portlet h3.subheader, .portlet h4.subheader, .portlet h5.subheader, .portlet h6.subheader {
  line-height: 1.4;
}

.portlet .portlet-title {
  font-weight: bold;
}

.portlet .operations {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
}

.portlet .operations a {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0.875rem 1.75rem 0.9375rem 1.75rem;
  font-size: 0.8125rem;
  padding-bottom: 0.9375rem;
  padding-top: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #00945D;
  border-color: #00764a;
  color: #FFFFFF;
  transition: background-color 300ms ease-out;
}

.portlet .operations a:hover, .portlet .operations a:focus {
  background-color: #00764a;
}

.portlet .operations a:hover, .portlet .operations a:focus {
  color: #FFFFFF;
}

.portlet .operations a.alert {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0.875rem 1.75rem 0.9375rem 1.75rem;
  font-size: 0.8125rem;
  padding-bottom: 0.9375rem;
  padding-top: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #BF0A0D;
  border-color: #99080a;
  color: #FFFFFF;
  transition: background-color 300ms ease-out;
}

.portlet .operations a.alert:hover, .portlet .operations a.alert:focus {
  background-color: #99080a;
}

.portlet .operations a.alert:hover, .portlet .operations a.alert:focus {
  color: #FFFFFF;
}

.summary, .note {
  font-size: 60%;
  padding: 0 0 1em;
  opacity: 0.7;
}

.top-bar .title-area h1 {
  width: 12em;
}

.pager li {
  font-size: 0.7em;
  margin-left: 0;
}

.pager li a {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.row.dashboard {
  width: 100% !important;
  max-width: none;
}

.row.dashboard table {
  width: 100%;
}

.row.dashboard table .users, .row.dashboard table .client {
  font-size: 0.7em;
}

.row.dashboard table .client {
  white-space: nowrap;
}

.deadline-list h3 {
  color: #fff;
}

.deadline-list td.title {
  font-size: 0.6em;
}

.deadline-list td.date, .deadline-list td.code {
  font-size: 0.7em;
}

.deadline-list.overdue {
  background-color: #BF0A0D;
  animation: overdue 1s ease-in 0 2;
}

@keyframes overdue {
  0% {
    background-color: #BF0A0D;
  }
  50% {
    background-color: #f2f2f2;
  }
  100% {
    background-color: #BF0A0D;
  }
}

.deadline-list.thisweek {
  background-color: #C86721;
}

.deadline-list.nextweek {
  background-color: #43AC6A;
}

.job-list {
  padding: 0.5em 0;
}

.job-list table {
  width: 100%;
}

.job-list table td.deadline-budget {
  width: 8em;
}

.task-list .task {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 0.5em 0;
  background-color: #fff;
}

.task-list .task i.sort, .task-list .task .check, .task-list .task .uncheck, .task-list .task a.remove {
  position: absolute;
  top: 0.45em;
  font-size: 1.2em;
}

.task-list .task i.sort {
  left: 0;
  display: none;
}

.task-list .task i.sort:hover {
  color: #00945D;
}

.task-list .task a.remove {
  right: 0;
  color: #222;
  display: none;
}

.task-list .task a.remove:hover {
  color: #00945D;
}

.task-list .task .check, .task-list .task .uncheck {
  left: 1.2em;
}

.task-list .task a.check, .task-list .task a.uncheck {
  color: #222;
}

.task-list .task a.check:hover {
  color: #00945D;
}

.task-list .task .textwrap {
  margin-left: 3em;
  margin-right: 2em;
}

.task-list .task textarea {
  box-shadow: none;
  padding: 0;
  overflow: hidden;
  min-height: 0;
  height: 1.2em;
  border-width: 0;
  resize: none;
  margin-bottom: 0;
  font-size: 0.8em;
}

.task-list .task textarea:focus {
  background-color: #fff;
}

.task-list .task:hover i.sort, .task-list .task:hover a.remove {
  display: block;
}

.task-list .done {
  text-decoration: line-through;
  color: #aaa;
}

.task-list .done textarea, .task-list .done a.check, .task-list .done a.uncheck, .task-list .done a.remove {
  color: #aaa;
}

.task-list .more {
  padding-top: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.overlay {
  display: none;
  height: 100%;
  min-height: 100%;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: white;
  opacity: 0.5;
}

#facebookG {
  width: 70px;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  top: 50%;
  margin-top: -25px;
}

#facebookG .facebook_blockG {
  background-color: #B8B8B8;
  border: 2px solid #757575;
  float: left;
  height: 49px;
  margin-left: 4px;
  width: 13px;
  opacity: 0.1;
  -moz-animation-name: bounceG;
  -moz-animation-duration: 1.4s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -moz-transform: scale(0.7);
  -webkit-animation-name: bounceG;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -webkit-transform: scale(0.7);
  -ms-animation-name: bounceG;
  -ms-animation-duration: 1.4s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -ms-transform: scale(0.7);
  -o-animation-name: bounceG;
  -o-animation-duration: 1.4s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  -o-transform: scale(0.7);
  animation-name: bounceG;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-direction: linear;
  transform: scale(0.7);
}

#facebookG #blockG_1 {
  -moz-animation-delay: 0.41999999999999993s;
  -webkit-animation-delay: 0.41999999999999993s;
  -ms-animation-delay: 0.41999999999999993s;
  -o-animation-delay: 0.41999999999999993s;
  animation-delay: 0.41999999999999993s;
}

#facebookG #blockG_2 {
  -moz-animation-delay: 0.5599999999999999s;
  -webkit-animation-delay: 0.5599999999999999s;
  -ms-animation-delay: 0.5599999999999999s;
  -o-animation-delay: 0.5599999999999999s;
  animation-delay: 0.5599999999999999s;
}

#facebookG #blockG_3 {
  -moz-animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
  -ms-animation-delay: 0.7s;
  -o-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

@-moz-keyframes bounceG {
  0% {
    -moz-transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(0.7);
    opacity: 0.1;
  }
}

@-webkit-keyframes bounceG {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.7);
    opacity: 0.1;
  }
}

@-ms-keyframes bounceG {
  0% {
    -ms-transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -ms-transform: scale(0.7);
    opacity: 0.1;
  }
}

@-o-keyframes bounceG {
  0% {
    -o-transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -o-transform: scale(0.7);
    opacity: 0.1;
  }
}

@keyframes bounceG {
  0% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.1;
  }
}

#fullscreen {
  padding: 0 2em;
}

.row.fullscreen {
  padding: 0 2em;
  width: 100% !important;
  max-width: none;
}

i.fi-loop {
  display: block;
  animation: spin 1s linear 0 infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#notify-nav.unread a {
  animation: notify 1s ease-in-out 0 infinite;
}

#notify-nav.unread a:hover {
  animation: none;
  background-color: #222222;
}

@keyframes notify {
  0% {
    background-color: #1E2528;
    color: #fff;
  }
  50% {
    background-color: #43AC6A;
    color: #1E2528;
  }
  100% {
    background-color: #1E2528;
    color: #fff;
  }
}

#notifications {
  position: absolute;
  top: 4em;
  left: 16.7em;
  width: 32em;
  background-color: #1E2528;
  font-size: 0.7em;
  list-style-type: none;
  z-index: 99;
}

#notifications a {
  border-top: 1px solid #374349;
  color: #fff;
  display: block;
  padding: 0.75em;
}

#notifications a:hover {
  background-color: #222222 !important;
}

#notifications li.unread a {
  background-color: #313c41;
}

#notifications time {
  font-size: 0.8em;
  display: block;
  color: #8197a1;
}

#notifications span.status.invoiced {
  color: #ccc;
}

#timesheetPanel {
  position: fixed;
  bottom: 0;
  right: 0;
  transition: height 250ms;
  opacity: 0.9;
  background-color: #1E2528;
  font-size: 0.8em;
  width: 30em;
  z-index: 99;
}

#timesheetPanel ul {
  font-size: 1em;
  list-style-type: none;
  margin: 0;
}

#timesheetPanel li {
  position: relative;
  height: 2em;
  line-height: 2em;
  transition: all 250ms;
  border-bottom: 1px solid #374349;
}

#timesheetPanel li:hover {
  background-color: #222222;
}

#timesheetPanel li.active {
  background-color: #358753;
  border-bottom: 1px solid #55bd7c;
}

#timesheetPanel li.active:hover {
  background-color: #43AC6A;
}

#timesheetPanel li.close {
  color: #999;
  text-align: center;
  font-size: 1.5em;
  border-bottom: none;
}

#timesheetPanel li.close:hover {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

#timesheetPanel a {
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
}

#timesheetPanel a.punch {
  width: 2em;
  left: 0;
  text-align: center;
}

#timesheetPanel a.punch:hover {
  background-color: #43AC6A;
}

#timesheetPanel a.title {
  left: 2em;
  right: 5em;
  padding-left: 0.5em;
  overflow: hidden;
}

#timesheetPanel a.title span {
  display: block;
}

#timesheetPanel a.time {
  width: 5em;
  right: 0;
  padding-right: 1em;
  text-align: right;
  font-family: 'Inconsolata', monospace;
}

#timesheetPanel.collapsed {
  height: 2em;
}

#timesheetPanel:hover {
  opacity: .95;
}

#timesheetPanel.expanded {
  height: 18em;
  overflow: auto;
}

div.progress.medium-alert .meter {
  background-color: #C86721;
}

#user-dash {
  max-width: 80em;
}

#user-dash .noteListForm {
  width: 100%;
}

#user-dash .noteListForm .row {
  background-color: #f6f6f6;
}

#user-dash .noteListForm .form-row {
  display: none;
}

#user-dash .summary {
  display: none;
}

#user-dash .active-projects a.client {
  color: #1E2528;
}

#user-dash .active-projects a.client:hover {
  color: #fff;
  background-color: #1E2528;
}

#user-dash .project-list .title {
  line-height: 1.5;
}

#user-dash .project-list .status, #user-dash .project-list .priority {
  text-align: right;
  font-size: 0.9em;
  margin-bottom: 1em;
}

#user-dash .project-list .task-list {
  font-size: 0.85em;
}

#user-dash .project-list .task-list .task {
  background-color: transparent;
}

#user-dash .project-list .task-list .task textarea {
  background-color: transparent;
}

#user-dash .project-list .deadline-budget {
  height: 3.5em;
  margin-bottom: 1em;
}

#user-dash .project-list .deadline-budget .progress {
  background-color: #cccccc;
}

#user-dash .project-list .deadline-budget i {
  font-size: 1.3em;
}

#user-dash .chart.timesheet {
  margin-bottom: 2em;
}

#user-dash .chart.timesheet ul.data {
  display: none;
}

#user-dash .chart.tasks {
  position: relative;
}

#user-dash .chart.tasks ul.data {
  position: absolute;
  top: 0;
  z-index: 2;
}

#user-dash .chart.tasks ul.data li {
  font-size: 0.85em;
}

#user-dash .chart.tasks .todo {
  color: #cccccc;
}

#user-dash .chart.tasks .completed {
  color: #43AC6A;
}

#user-dash .chart.tasks .diagram {
  height: 150px;
  font-size: 1.2em;
}

#user-dash .chart.timesheet ul li {
  color: #00945D;
}

#user-dash .client .phone, #user-dash .client .address, #user-dash .client .code {
  font-size: 0.8em;
  margin-top: 1em;
  line-height: 1.2;
}

.reminderList .reminder {
  margin-bottom: 1em;
  background-color: #f3f3f3;
}

.reminderList .remind.me {
  background-color: #BF0A0D;
}

.reminderList .remind {
  background-color: #1E2528;
  color: #fff;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  text-shadow: 1px 1px 1px #000;
}

.reminderList .entity {
  background-color: #bdd0c9;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
}

.reminderList .entity a {
  color: #1E2528;
}

.reminderList .text {
  font-size: 0.7em;
  padding: 1em;
}

.reminderList .author {
  font-size: 0.6em;
  opacity: 0.7;
  padding: 0.5em;
}

.reminderList .done .button {
  opacity: 0.5;
  padding: 0.5em 0.75em;
  margin-bottom: 0.5em;
}

.reminderList .done .button:hover {
  opacity: 1;
}

.reconcile-virtualmin table.totals {
  margin-bottom: 2em;
}

.reconcile-virtualmin table.totals .total {
  font-weight: bold;
}

.reconcile-virtualmin table.totals tbody tr.total td {
  border-top-style: double;
}

.reconcile-virtualmin table.totals tbody td.total {
  border-left-style: double;
}

.reconcile-virtualmin table.totals tr.disabled td, .reconcile-virtualmin table.totals tr.disabled th {
  color: #BF0A0D;
}

.reconcile-virtualmin table.totals tr.ip-mismatch td, .reconcile-virtualmin table.totals tr.ip-mismatch th {
  color: #f08a24;
}

.reconcile-virtualmin #reconcile-grid {
  font-size: 0.8em;
}

.reconcile-virtualmin #reconcile-grid tr.disabled td, .reconcile-virtualmin #reconcile-grid tr.disabled th {
  background-color: #fa9b9d;
}

.reconcile-virtualmin #reconcile-grid tr.ip-mismatch td, .reconcile-virtualmin #reconcile-grid tr.ip-mismatch th {
  background-color: #fbe3cb;
}

.reconcile-virtualmin #reconcile-grid .select {
  padding: 0;
}

.reconcile-virtualmin #reconcile-grid .select a {
  padding: 0.2em 0.5em;
}

.reconcile-virtualmin #reconcile-grid .checkbox {
  padding: 0;
}

.reconcile-virtualmin #reconcile-grid .checkbox input {
  margin-top: 0.5em;
}

.reconcile-virtualmin #reconcile-grid input, .reconcile-virtualmin #reconcile-grid select {
  margin-bottom: 0;
  font-size: 1em;
  height: auto;
  padding: 0.1em 0.3em;
}

.reconcile-virtualmin #reconcile-grid .billingPriceCustom input {
  width: 8em;
}

.reconcile-virtualmin #reconcile-grid select {
  height: 2em;
}

.reconcile-virtualmin .hasNotes {
  color: #BF0A0D;
  font-weight: bold;
}

.noteListForm {
  margin-bottom: 2em;
}

.noteListForm form {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */
}

.noteListForm textarea {
  line-height: 1.5;
  margin-bottom: 0.5em;
}

.noteListForm .note-list {
  font-size: 0.8em;
  clear: both;
}

.noteListForm .note-list .row {
  border-bottom: 1px solid #edead4;
}

.noteListForm .note-list .columns {
  padding-bottom: 4em;
  padding-top: 4em;
}

.noteListForm .note-list .date {
  color: #999;
  font-size: 0.8em;
}

.noteListForm .note-list .lead {
  font-size: 0.8em;
  position: absolute;
  left: 1.5em;
  top: 2em;
  color: #43AC6A;
  opacity: 1;
}

.noteListForm .note-list .type {
  font-size: 0.8em;
  position: absolute;
  right: 2em;
  top: 2em;
  line-height: 1;
}

.noteListForm .note-list .reminder {
  position: absolute;
  left: 1.5em;
  bottom: 1.7em;
  font-size: 0.8em;
  opacity: 1;
  text-transform: uppercase;
}

.noteListForm .note-list .reminder .button {
  margin-bottom: 0;
  padding: 0.4em 0.4em 0.4em 0.4em;
}

.noteListForm .note-list .reminder .reminderLabel {
  margin-right: 1em;
}

.noteListForm .note-list .reminder.done .reminderLabel {
  opacity: 0.5;
}

.noteListForm .note-list .reminder.done .fa-check {
  color: #43AC6A;
  font-size: 1.5em;
}

.noteListForm .note-list .rating {
  position: absolute;
  right: 2em;
  bottom: 1.7em;
  font-size: 0.8em;
}

.noteListForm .note-list .rating i {
  opacity: 1;
}

.noteListForm .note-list .rating.rate1,
.noteListForm .note-list .rating.rate2 {
  color: #BF0A0D;
}

.noteListForm .note-list .rating.rate3 {
  color: #f08a24;
}

.noteListForm .note-list .rating.rate4,
.noteListForm .note-list .rating.rate5 {
  color: #43AC6A;
}

.noteListForm .form-row {
  font-size: 0.8em;
  padding-top: 4em;
}

.noteListForm .fa {
  font-size: 1.1em;
}

.noteListForm .reminder {
  position: relative;
  opacity: 0.5;
}

.noteListForm .reminder .fa {
  cursor: pointer;
}

.noteListForm .reminder input {
  margin-left: 1em;
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  height: 1.5em;
  padding: 0;
  width: auto;
  display: inline;
}

.noteListForm .reminder select {
  display: none;
  width: auto;
  background-color: transparent;
  border: none;
  padding: 0 2em 0 0.5em;
  margin-bottom: 0;
  height: 1.5em;
}

.noteListForm .reminder:hover, .noteListForm .reminder.active {
  opacity: 1;
}

.noteListForm .reminder.active select {
  display: inline;
}

.noteListForm .type {
  padding-bottom: 0em;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.1em;
}

.noteListForm .type a.dropdown {
  opacity: 0.5;
}

.noteListForm .type a.dropdown:hover {
  opacity: 1;
}

.noteListForm .type input {
  font-size: 1em;
  display: none;
  width: 10em;
  margin-bottom: 0;
  height: 1em;
  padding-left: 0;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.noteListForm .type.active a.dropdown {
  opacity: 1;
}

.noteListForm .type.active input {
  display: inline;
}

.noteListForm .lead {
  opacity: 0.5;
  line-height: 1;
}

.noteListForm .lead label.text {
  display: inline !important;
  color: #00945D;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.1em;
}

.noteListForm .lead input {
  margin-bottom: 0;
}

.noteListForm .lead .switch {
  line-height: 1;
  margin-bottom: 0;
  display: inline-block;
}

.noteListForm .lead .switch label {
  height: 0.8em;
  width: 2em;
}

.noteListForm .lead .switch label:after {
  height: 0.5em;
  width: 0.6em;
  top: 0.1em;
}

.noteListForm .lead .switch input:checked + label:after {
  left: 1.1em;
}

.noteListForm .lead .switch input:checked + label {
  background-color: #43AC6A;
}

.noteListForm .lead:hover, .noteListForm .lead.active {
  opacity: 1;
}

.noteListForm.dir-down .note-list .row {
  border-top: 1px solid #edead4;
  border-bottom: none;
}

.noteListForm.dir-down .form-row {
  padding-top: 4em;
  padding-bottom: 0;
}

.rating {
  list-style-type: none;
  text-align: right;
}

.rating i {
  opacity: 0.5;
  font-size: 1.3em;
  cursor: pointer;
}

.rating .avg {
  margin-left: 0.5em;
}

.rating span:hover > i {
  opacity: 1;
}

.rating span:hover > i.rate1 {
  color: #BF0A0D;
}

.rating span:hover > i.rate2 {
  color: #f08a24;
}

.rating span:hover > i.rate3 {
  color: #f08a24;
}

.rating span:hover > i.rate4 {
  color: #43AC6A;
}

.rating span:hover > i.rate5 {
  color: #43AC6A;
}

.rating.active1 i.rate1 {
  color: #BF0A0D;
  opacity: 1;
  font-weight: bold;
}

.rating.active2 i.rate1 {
  color: #BF0A0D;
  opacity: 1;
}

.rating.active2 i.rate2 {
  color: #f08a24;
  opacity: 1;
  font-weight: bold;
}

.rating.active3 i.rate1 {
  color: #BF0A0D;
  opacity: 1;
}

.rating.active3 i.rate2 {
  color: #f08a24;
  opacity: 1;
}

.rating.active3 i.rate3 {
  color: #f08a24;
  opacity: 1;
  font-weight: bold;
}

.rating.active4 i.rate1 {
  color: #BF0A0D;
  opacity: 1;
}

.rating.active4 i.rate2 {
  color: #f08a24;
  opacity: 1;
}

.rating.active4 i.rate3 {
  color: #f08a24;
  opacity: 1;
}

.rating.active4 i.rate4 {
  color: #43AC6A;
  opacity: 1;
  font-weight: bold;
}

.rating.active5 i.rate1 {
  color: #BF0A0D;
  opacity: 1;
}

.rating.active5 i.rate2 {
  color: #f08a24;
  opacity: 1;
}

.rating.active5 i.rate3 {
  color: #f08a24;
  opacity: 1;
}

.rating.active5 i.rate4 {
  color: #43AC6A;
  opacity: 1;
}

.rating.active5 i.rate5 {
  color: #43AC6A;
  opacity: 1;
  font-weight: bold;
}

.tabs-content {
  background-color: #f5f3e6;
  padding: 1em;
}

.tabs-content table tbody td {
  background-color: rgba(255, 255, 255, 0.7);
}

.tabs-content .noteListForm {
  background-color: rgba(255, 255, 255, 0.7);
}

.tabs dd > a, .tabs .tab-title > a {
  padding: 0.5em 1.5em;
  outline: none;
  font-size: 0.9em;
}

dl.tabs dd a {
  border-right: 1px solid #d7cf9e;
}

dl.tabs dd .count {
  position: absolute;
  bottom: 0.2em;
  right: 0.6em;
  font-size: 0.6em;
  opacity: 0.4;
}

dl.tabs dd.active a {
  border-right: none;
}

#project-grid td {
  font-size: 0.75em;
}

#client_notes h2 {
  display: none;
}

ul.sub-menu {
  display: block;
  margin: -0.25rem 0 1.125rem;
  overflow: hidden;
  padding-top: 0.25rem;
  width: auto;
  list-style-type: none;
  float: right;
  overflow: visible;
}

ul.sub-menu dt {
  text-transform: uppercase;
}

ul.sub-menu dt,
ul.sub-menu dd,
ul.sub-menu li {
  color: #999999;
  float: left;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  margin-left: 1rem;
  margin-bottom: 0;
}

ul.sub-menu dt a,
ul.sub-menu dd a,
ul.sub-menu li a {
  color: #999999;
  padding: 0.1875rem 1rem;
  text-decoration: none;
}

ul.sub-menu dt a:hover,
ul.sub-menu dd a:hover,
ul.sub-menu li a:hover {
  color: #737373;
}

ul.sub-menu dt.active a,
ul.sub-menu dd.active a,
ul.sub-menu li.active a {
  border-radius: 3px;
  background: #00945D;
  color: #FFFFFF;
  cursor: default;
  font-weight: normal;
  padding: 0.1875rem 1rem;
}

ul.sub-menu dt.active a:hover,
ul.sub-menu dd.active a:hover,
ul.sub-menu li.active a:hover {
  background: #007f50;
}

ul.sub-menu li {
  font-size: 0.8em;
  margin: 0;
}

ul.sub-menu li a {
  padding: 0.5em 1em;
  color: #00945D;
  background-color: rgba(0, 148, 93, 0.1);
  border: 1px solid rgba(0, 148, 93, 0.2);
}

ul.sub-menu li a:hover {
  color: #00945D;
  background-color: rgba(0, 148, 93, 0.2);
  border: 1px solid rgba(0, 148, 93, 0.3);
}

ul.sub-menu a.alert {
  color: #BF0A0D;
}

.client.view .top-info {
  position: absolute;
  top: 2.5em;
  right: 1em;
}

#client_dashboard .summary {
  display: none;
}

#client_dashboard table {
  width: 100%;
}

#client_dashboard td {
  font-size: 0.8em !important;
}

#client_dashboard .noteListForm {
  padding: 0 1em;
}

#client_dashboard .noteListForm h2,
#client_dashboard .noteListForm .form-row {
  display: none;
}

#client_notes .noteListForm {
  padding: 0 2em;
}

#client_contacts table {
  clear: both;
}

canvas.chart {
  margin-bottom: 2em;
}
